import jwt from 'jsonwebtoken';

export const authenticateSession = () => {
  const token = localStorage.getItem("token");

  if (token) {
    return jwt.verify(token, `${process.env.REACT_APP_TOKEN_SECRET}`, (error, data) => {
      if (error) {
        console.log(error);
        localStorage.removeItem("token");
        // localStorage.setItem("session", "Session expired, please login again.");
        // window.location.pathname = "/";
      };

      if (Date.now() >= data && data.exp * 1000) {
        localStorage.removeItem("token");
        // localStorage.setItem("session", "Session expired, please login again.");
        // window.location.pathname = "/";
      };

      return true;
    });
  } else {
    return false;
    // window.location.pathname = "/";
  };
};

export const parseCSVToJSON = (csvData) => {
  const lines = csvData.split("\n");
  const result = [];
  let headers = lines[0].split(";");
      headers[headers.length -1] = headers[headers.length -1].replace(/\r/g,"")

  for (let i = 1; i < lines.length; i++) {        
    if (!lines[i])
      continue

    const obj = {};
    const currentline = lines[i].split(';');

    for (let j = 0; j < headers.length; j++) {
      obj[headers[j]] = currentline[j].replace(/\r/g,"");
    }

    result.push(obj);
  };

  return JSON.stringify(result);
};

export const convertToShippingDays = (leadTime) => {
  switch (leadTime) {
    case "JHB":
      return "1 Day";
    case "DBN":
      return "2 Days";
    case "PTA":
      return "2 Days";
    case "CPT":
      return "3 Days";
    default:
      return;
  };
};

export const svgAssetStyles = (marginTop, marginRight, marginBottom, marginLeft, width, filter, transform) => {
  return {
    marginTop: marginTop ? marginTop : "auto",
    marginRight: marginRight ? marginRight : "auto",
    marginBottom: marginBottom ? marginBottom : "auto",
    marginLeft: marginLeft ? marginLeft : "auto",
    minWidth: width ? width : "auto",
    width: width ? width : "auto",
    filter: filter ? `invert(${filter})` : "none",
    transform: transform ? transform : "none"
  }
};

export const URLParams = (key, url) => {
  const href = url ? url : window.location.href;
  const reg = new RegExp( '[?&]' + key + '=([^&#]*)', 'i' );
  const string = reg.exec(href);
  return string ? string[1] : null;
};