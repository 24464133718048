import React, { Component, Fragment } from "react";
import { withApollo } from "react-apollo";

import Result from "./Result";
import ContactModal from "./ContactModal";

import { CONTACT_REQUEST } from "../../graphql/mutations/Navbar";

import { svgAssetStyles } from "../../utils";

import cartSvg from "../../assets/svg/cart.svg";
import chevronSvg from "../../assets/svg/chevron.svg";
import timesSvg from "../../assets/svg/times.svg";

class NavBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isCart: false,
      isContact: false,
      isControls: false,
      name: "",
      email: "",
      success: "",
      errors: {
        graphQL: "",
        name: "",
        email: "",
        notes: "",
      },
    };
  }

  userDefault = (isLoggedIn) => {
    const { history } = this.props;

    if (!isLoggedIn) {
      if (history.location === "/") {
        history.push("/", {
          goTo: "home",
        });
      } else {
        history.push("/");
      }
    } else {
      history.push("/results/0");
    }
  };

  userAuthenticate = (isLoggedIn) => {
    const { history, client } = this.props;

    if (!isLoggedIn) {
      history.push("/login");
    } else {
      client.cache.reset();
      localStorage.removeItem("userSellerId");
      localStorage.removeItem("token");
      history.push("/");
    }
  };

  onChange = (event) => {
    this.setState({
      success: "",
      errors: {
        graphQL: "",
        name: "",
        email: "",
        notes: "",
      },
    });

    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  contact = async () => {
    const { client } = this.props;
    const { name, email, notes } = this.state;

    try {
      await client.mutate({
        mutation: CONTACT_REQUEST,
        variables: {
          fullName: name,
          email: email,
          notes: notes,
        },
      });

      this.setState({
        isLoading: false,
        isContact: false,
        success:
          "Thank you for contacting us. We will get back to you within 24 hours.",
      });
    } catch (error) {
      this.setState({
        isLoading: false,
        errors: {
          graphQL: error.message,
        },
      });
    }
  };

  onSubmit = (event) => {
    event.preventDefault();

    const { name, email, notes } = this.state;
    let errors = 0;

    if (name === "") {
      errors++;
      this.setState({
        errors: {
          name: "Please type your name & surname.",
        },
      });
    } else if (email === "") {
      errors++;
      this.setState({
        errors: {
          email: "Please type your email.",
        },
      });
    } else if (notes === "") {
      errors++;
      this.setState({
        errors: {
          notes: "Please type your notes.",
        },
      });
    }

    if (errors === 0) {
      this.setState({
        isLoading: true,
        isOrdering: false,
      });

      this.contact();
    }
  };

  closeResult = () => {
    this.setState({
      success: "",
      errors: {
        graphQL: "",
        name: "",
        email: "",
        notes: "",
      },
    });
  };

  render() {
    const {
      history,
      isLoggedIn,
      inventoryData,
      inventoryCart,
      updateCart,
      updateQuantity,
      importInventory,
      deleteAllComponents,
    } = this.props;
    const {
      isCart,
      isContact,
      isControls,
      name,
      email,
      notes,
      success,
      errors,
    } = this.state;

    const userOrders = JSON.parse(localStorage.getItem("userOrders"));
    let imgTransform =
      isCart || isControls ? "rotate(90deg)" : "rotate(-90deg)";

    return (
      <Fragment>
        {(errors.graphQL !== "" ||
          errors.name !== "" ||
          errors.email !== "" ||
          errors.notes !== "" ||
          success !== "") && (
          <Result
            resultVariant={
              errors.graphQL !== "" ||
              errors.name !== "" ||
              errors.email !== "" ||
              errors.notes !== ""
                ? "error"
                : "success"
            }
            resultText={
              errors.graphQL ||
              errors.name ||
              errors.email ||
              errors.notes ||
              success
            }
            resultOnClick={this.closeResult}
          />
        )}

        <nav className="navbar">
          <div className="container">
            <div className="navbar-brand">
              <a
                href="/"
                className="navbar-item"
                onClick={(event) => this.userDefault(isLoggedIn)}
              >
                Home
              </a>

              <span
                className="navbar-burger burger"
                data-target="navbarMenuHeroA"
              >
                <span></span>
                <span></span>
                <span></span>
              </span>
            </div>
            <div id="navbarMenuHeroA" className="navbar-menu">
              <div className="navbar-end">
                <span className="navbar-item">
                  <button
                    className="button is-dark"
                    onClick={(event) => history.push("/terms")}
                  >
                    Terms Of Use
                  </button>
                </span>

                <span className="navbar-item">
                  <button
                    className="button is-dark"
                    onClick={(event) => history.push("/contact")}
                  >
                    Get In Touch
                  </button>
                </span>

                {isLoggedIn && (
                  <Fragment>
                    <span className="navbar-item">
                      <button
                        className="button is-dark"
                        onClick={(event) => event.preventDefault()}
                      >
                        Total Orders {userOrders.length}
                      </button>
                    </span>
                    <div
                      className={
                        isControls
                          ? "navbar-item has-dropdown is-active"
                          : "navbar-item has-dropdown"
                      }
                    >
                      <a
                        href="#actions"
                        className="button is-info"
                        onClick={() =>
                          this.setState({
                            isControls: !isControls,
                            isCart: false,
                          })
                        }
                      >
                        Actions...
                        <img
                          src={chevronSvg}
                          alt="Actions"
                          style={svgAssetStyles(
                            "2px",
                            null,
                            "0px",
                            "10px",
                            "6px",
                            "1",
                            imgTransform
                          )}
                        />
                      </a>

                      <div className="navbar-dropdown is-right is-boxed">
                        <div className="controls">
                          <span className="navbar-item">
                            <a
                              href="#create"
                              className="button is-dark"
                              onClick={(event) => history.push("/create")}
                            >
                              Create Component
                            </a>
                          </span>
                          <span className="navbar-item">
                            <label className="button is-dark" htmlFor="import">
                              Import CSV
                              <input
                                id="import"
                                name="import"
                                type="file"
                                onChange={(event) => importInventory(event)}
                                accept=".csv"
                                style={{
                                  position: "absolute",
                                  zIndex: -9999,
                                  width: "10px",
                                }}
                              />
                            </label>
                          </span>
                          {inventoryData && inventoryData.length > 0 && (
                            <span className="navbar-item">
                              <a
                                href="#login"
                                className="button is-dark"
                                onClick={(event) => deleteAllComponents(event)}
                              >
                                Delete All
                              </a>
                            </span>
                          )}
                        </div>
                        <hr className="navbar-divider" />
                        <a
                          href="#logout"
                          className="navbar-item button is-info"
                          style={{ margin: "0 0.5rem" }}
                          onClick={(event) => this.userAuthenticate(true)}
                        >
                          Logout
                        </a>
                      </div>
                    </div>
                  </Fragment>
                )}

                {!isLoggedIn && (
                  <span className="navbar-item">
                    <a
                      href="#register"
                      className="button is-dark"
                      onClick={(event) => history.push("/register")}
                    >
                      Register
                    </a>
                  </span>
                )}

                {!isLoggedIn && (
                  <span className="navbar-item">
                    <a
                      href="#login"
                      className="button is-info"
                      onClick={(event) => this.userAuthenticate(false)}
                    >
                      Login
                    </a>
                  </span>
                )}

                <div
                  className={
                    isCart
                      ? "navbar-item has-dropdown is-active"
                      : "navbar-item has-dropdown"
                  }
                >
                  <a
                    href="#inventory"
                    className="button is-info"
                    onClick={() =>
                      this.setState({ isCart: !isCart, isControls: false })
                    }
                  >
                    <img
                      src={cartSvg}
                      alt="Your Cart"
                      style={svgAssetStyles(
                        null,
                        "10px",
                        null,
                        null,
                        "18px",
                        "1",
                        null
                      )}
                    />
                    Your Cart ({inventoryCart.length})
                    {inventoryCart.length > 0 && (
                      <img
                        src={chevronSvg}
                        alt="Cart"
                        style={svgAssetStyles(
                          "2px",
                          null,
                          "0px",
                          "10px",
                          "6px",
                          "1",
                          imgTransform
                        )}
                      />
                    )}
                  </a>

                  {inventoryCart.length > 0 && (
                    <div className="navbar-dropdown is-boxed">
                      <div className="inventory">
                        {inventoryCart.map((data, index) => {
                          return (
                            <a
                              href="#inventory"
                              key={index}
                              className="navbar-item"
                            >
                              <span>
                                {data.manufacturerName}:{" "}
                                {data.manufacturerPartNumber}
                              </span>
                              <input
                                id={index}
                                className="input"
                                type="number"
                                min={1}
                                max={parseInt(data.quantityAvailable)}
                                value={data.quantity}
                                onChange={(event) =>
                                  updateQuantity(data, event.target.value)
                                }
                              />
                              <img
                                src={timesSvg}
                                alt="Remove from Cart"
                                onClick={() => updateCart("remove", data)}
                              />
                            </a>
                          );
                        })}
                      </div>
                      <hr className="navbar-divider" />
                      <a
                        href="#checkout"
                        className="navbar-item button is-info"
                        style={{ margin: "0 0.5rem" }}
                        onClick={(event) => history.push("/checkout")}
                      >
                        Checkout
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </nav>

        {isContact && (
          <ContactModal
            name={name}
            email={email}
            notes={notes}
            modalOnChange={(event) => this.onChange(event)}
            modalOnSubmit={(event) => this.onSubmit(event)}
            modalOnClickClose={() => this.setState({ isContact: !isContact })}
          />
        )}
      </Fragment>
    );
  }
}

export default withApollo(NavBar);
