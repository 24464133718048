import React, { Component, Fragment } from "react";
import { withApollo } from "react-apollo";

import Loading from "../components/common/Loading";
import Result from "../components/common/Result";
import NavBar from "../components/common/NavBar";
import Footer from "../components/common/Footer";

import { GET_COMPONENT } from "../graphql/queries/Components";
import {
  IMPORT_COMPONENTS,
  DELETE_ALL_COMPONENTS,
} from "../graphql/mutations/Results";

import {
  authenticateSession,
  parseCSVToJSON,
  convertToShippingDays,
  svgAssetStyles,
} from "../utils";

import chevronSvg from "../assets/svg/chevron.svg";
import addSvg from "../assets/svg/add.svg";
import timesSvg from "../assets/svg/times.svg";

class Details extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isLoggedIn: false,
      componentDetails: [],
      inventoryCart: [],
      orderTotal: 0,
      success: "",
      errors: {
        graphQL: "",
      },
    };
  }

  getComponent = async () => {
    const { client, match } = this.props;
    const id = match.params.id;

    try {
      const response = await client.query({
        query: GET_COMPONENT,
        variables: {
          id: id,
        },
      });

      let { data } = response;

      this.setState({
        isLoading: false,
        componentDetails: data.getComponent,
      });
    } catch (error) {
      console.log(error);
    }
  };

  componentDidMount() {
    const inventoryCart = JSON.parse(localStorage.getItem("inventoryCart"));

    this.setState({
      isLoading: true,
    });

    this.getComponent();

    if (inventoryCart) {
      this.setState({
        inventoryCart: inventoryCart,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { match } = this.props;

    if (!match.params.string) {
      const isLoggedIn = authenticateSession();

      if (prevState.isLoggedIn !== isLoggedIn) {
        this.setState({
          isLoggedIn: isLoggedIn,
        });
      }
    }
  }

  import = async (parsedCSV) => {
    const { client } = this.props;
    const userSellerId = localStorage.getItem("userSellerId");

    try {
      const response = await client.mutate({
        mutation: IMPORT_COMPONENTS,
        variables: {
          userSellerId: userSellerId,
          input: parsedCSV,
        },
      });

      let { data } = response;

      this.setState({
        isLoading: false,
        inventoryData: data.importComponents,
        success: "Data has been uploaded.",
      });
    } catch (error) {
      this.setState({
        isLoading: false,
        errors: {
          graphQL: error.message,
        },
      });
    }
  };

  importInventory = (event) => {
    const csvData = event.target.files[0];

    this.setState({
      isLoading: true,
    });

    const csvReader = new FileReader();
    csvReader.readAsText(csvData);
    csvReader.onload = function (e) {
      let result = JSON.parse(parseCSVToJSON(e.target.result));

      this.import(result);
    }.bind(this);
  };

  deleteAllComponents = async () => {
    const { client } = this.props;

    this.setState({
      isLoading: true,
    });

    try {
      const response = await client.mutate({
        mutation: DELETE_ALL_COMPONENTS,
      });

      let { data } = response;

      this.getComponents();

      this.setState({
        isLoading: false,
        success: data.deleteAllComponents.success,
      });
    } catch (error) {
      console.log(error);
    }
  };

  updateCart = (action, data) => {
    const inventoryCartList = this.state.inventoryCart;
    const component = data;

    switch (action) {
      case "add":
        component.quantity = 1;
        inventoryCartList.push(component);
        this.setState({
          inventoryCart: inventoryCartList,
        });
        localStorage.setItem(
          "inventoryCart",
          JSON.stringify(inventoryCartList)
        );
        break;
      case "remove":
        const index = inventoryCartList.findIndex(
          (obj) => obj.id === component.id
        );
        inventoryCartList.splice(index, 1);
        this.setState({
          inventoryCart: inventoryCartList,
        });
        localStorage.setItem(
          "inventoryCart",
          JSON.stringify(inventoryCartList)
        );
        break;
      default:
        break;
    }

    this.orderTotal();
  };

  updateQuantity = (data, value) => {
    const inventoryCartList = this.state.inventoryCart;
    const component = data;
    const index = inventoryCartList.findIndex((obj) => obj.id === component.id);

    inventoryCartList[index].quantity = value;

    this.setState({
      inventoryCart: inventoryCartList,
    });

    localStorage.setItem("inventoryCart", JSON.stringify(inventoryCartList));

    this.orderTotal();
  };

  orderTotal = () => {
    const inventoryCart = JSON.parse(localStorage.getItem("inventoryCart"));
    let total = 0.0;

    inventoryCart.map((component) => {
      total =
        total +
        parseFloat(
          component.resalePrice.replace(",", ".") * component.quantity
        );
      return null;
    });

    this.setState({
      orderTotal: total.toFixed(2),
    });
  };

  closeResult = () => {
    this.setState({
      success: "",
      errors: {
        graphQL: "",
      },
    });
  };

  render() {
    const { history, match, client } = this.props;
    const {
      isLoading,
      isLoggedIn,
      inventoryData,
      inventoryCart,
      componentDetails,
      success,
      errors,
    } = this.state;

    const dataIndex = inventoryCart.findIndex(
      (obj) => obj.id === componentDetails.id
    );

    return (
      <Fragment>
        {isLoading && <Loading loadingLabel={"Please wait..."} />}

        {(errors.graphQL !== "" || success !== "") && (
          <Result
            resultVariant={errors.graphQL !== "" ? "error" : "success"}
            resultText={errors.graphQL || success}
            resultOnClick={this.closeResult}
          />
        )}

        <section className="hero is-dark is-fullheight details">
          <div className="hero-head">
            <NavBar
              history={history}
              client={client}
              isLoggedIn={isLoggedIn}
              inventoryData={inventoryData}
              inventoryCart={inventoryCart}
              importInventory={(event) => this.importInventory(event)}
              componentDetails={componentDetails}
              deleteAllComponents={(event) => this.deleteAllComponents(event)}
              updateCart={this.updateCart}
              updateQuantity={this.updateQuantity}
            />
          </div>

          {componentDetails.hasOwnProperty("id") && (
            <div className="hero-body">
              <div className="container has-text-centered">
                <h1 className="title" style={{ marginBottom: 0 }}>
                  Component Details
                </h1>

                <p>
                  <a
                    href="#back"
                    onClick={() =>
                      match.params.string
                        ? history.push(`/results/${match.params.string}`)
                        : history.push(`/results/0`)
                    }
                    className="button is-dark"
                  >
                    <img
                      src={chevronSvg}
                      alt="Go Back to Search Results"
                      style={svgAssetStyles(
                        null,
                        "10px",
                        null,
                        null,
                        "6px",
                        "1",
                        null
                      )}
                    />{" "}
                    Go Back to Search Results
                  </a>
                </p>

                <div className="columns" style={{ marginTop: "1rem" }}>
                  <div className="column is-details is-half">
                    <div className="columns ">
                      <div className="column has-text-left">
                        <p>
                          <strong>Seller Id:</strong>
                        </p>
                      </div>
                      <div className="column has-text-left">
                        <p>{componentDetails.sellerId}</p>
                      </div>
                    </div>

                    <div className="columns">
                      <div className="column has-text-left">
                        <p>
                          <strong>Manufacturer Part Number:</strong>
                        </p>
                      </div>
                      <div className="column has-text-left">
                        <p>{componentDetails.manufacturerPartNumber}</p>
                      </div>
                    </div>

                    <div className="columns">
                      <div className="column has-text-left">
                        <p>
                          <strong>Manufacturer Name:</strong>
                        </p>
                      </div>
                      <div className="column has-text-left">
                        <p>{componentDetails.manufacturerName}</p>
                      </div>
                    </div>

                    <div className="columns">
                      <div className="column has-text-left">
                        <p>
                          <strong>Part Description:</strong>
                        </p>
                      </div>
                      <div className="column has-text-left">
                        <p>{componentDetails.partDescription}</p>
                      </div>
                    </div>

                    <div className="columns">
                      <div className="column has-text-left">
                        <p>
                          <strong>Quantity Available:</strong>
                        </p>
                      </div>
                      <div className="column has-text-left">
                        <p>{componentDetails.quantityAvailable}</p>
                      </div>
                    </div>

                    <div className="columns">
                      <div className="column has-text-left">
                        <p>
                          <strong>Minimum Order Quantity:</strong>
                        </p>
                      </div>
                      <div className="column has-text-left">
                        <p>{componentDetails.minimumOrderQuantity}</p>
                      </div>
                    </div>

                    <div className="columns">
                      <div className="column has-text-left">
                        <p>
                          <strong>Date Code Range:</strong>
                        </p>
                      </div>
                      <div className="column has-text-left">
                        <p>{componentDetails.dateCodeRange}</p>
                      </div>
                    </div>

                    <div className="columns">
                      <div className="column has-text-left">
                        <p>
                          <strong>Authorised Channel:</strong>
                        </p>
                      </div>
                      <div className="column has-text-left">
                        <p>{componentDetails.authorisedChannel}</p>
                      </div>
                    </div>

                    <div className="columns">
                      <div className="column has-text-left">
                        <p>
                          <strong>Lead Time:</strong>
                        </p>
                      </div>
                      <div className="column has-text-left">
                        <p>{componentDetails.leadTime}</p>
                      </div>
                    </div>

                    <div className="columns">
                      <div className="column has-text-left">
                        <p>
                          <strong>Package Type:</strong>
                        </p>
                      </div>
                      <div className="column has-text-left">
                        <p>{componentDetails.packageType}</p>
                      </div>
                    </div>

                    {componentDetails.datasheetLink && (
                      <div className="columns">
                        <div className="column has-text-left">
                          <p>
                            <strong>Datasheet:</strong>
                          </p>
                        </div>
                        <div className="column has-text-left">
                          <p>
                            <a
                              href={componentDetails.datasheetLink}
                              className="is-dark"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Download
                            </a>
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="column">
                    <h2 className="subtitle">
                      Price: R{" "}
                      {parseFloat(
                        componentDetails.resalePrice.replace(",", ".")
                      ).toFixed(2)}
                    </h2>

                    <p>
                      Ships In:{" "}
                      {convertToShippingDays(componentDetails.leadTime)}
                    </p>

                    {dataIndex === -1 && (
                      <p
                        className="control has-text-centered"
                        style={{ marginTop: "10px" }}
                      >
                        <button
                          className="button is-info is-half"
                          onClick={() =>
                            this.updateCart("add", componentDetails)
                          }
                        >
                          <img
                            src={addSvg}
                            alt="Add to Cart"
                            style={svgAssetStyles(
                              "2px",
                              "5px",
                              "0px",
                              null,
                              "12px",
                              "1",
                              null
                            )}
                          />{" "}
                          Cart
                        </button>
                      </p>
                    )}

                    {dataIndex > -1 && (
                      <p
                        className="control has-text-centered"
                        style={{ marginTop: "10px" }}
                      >
                        <button
                          className="button is-danger is-half"
                          onClick={() =>
                            this.updateCart("remove", componentDetails)
                          }
                        >
                          <img
                            src={timesSvg}
                            alt="Remove from Cart"
                            style={svgAssetStyles(
                              "2px",
                              "5px",
                              "0px",
                              null,
                              "10px",
                              "1",
                              null
                            )}
                          />{" "}
                          Cart
                        </button>
                      </p>
                    )}

                    <div className="columns" style={{ marginTop: "1rem" }}>
                      <div
                        className="thumbnail"
                        style={{
                          backgroundImage: `url(${
                            componentDetails.imageLink
                              ? componentDetails.imageLink
                              : "https://via.placeholder.com/500"
                          })`,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <Footer history={history} />
        </section>
      </Fragment>
    );
  }
}

export default withApollo(Details);
