import React, { Component, Fragment } from "react";
import { withApollo } from "react-apollo";

import Loading from "../components/common/Loading";
import NavBar from "../components/common/NavBar";
import Footer from "../components/common/Footer";
import Result from "../components/common/Result";

import { USER_LOGIN } from "../graphql/mutations/Login";

import defaultAltPng from "../assets/png/default-alt.png";

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      inventoryCart: [],
      userEmail: "",
      userPassword: "",
      success: "",
      errors: {
        name: "",
        userEmail: "",
        userPassword: "",
        graphQL: "",
      },
    };
  }

  componentDidMount() {
    const inventoryCart = JSON.parse(localStorage.getItem("inventoryCart"));

    if (inventoryCart) {
      this.setState({
        inventoryCart: inventoryCart,
      });
    }

    localStorage.removeItem("userSellerId");
    localStorage.removeItem("userOrders");
    localStorage.removeItem("token");
  }

  onChange = (event) => {
    this.setState({
      success: "",
      errors: {
        userEmail: "",
        userPassword: "",
        graphQL: "",
      },
    });

    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  onSubmit = async (event) => {
    event.preventDefault();
    const { client } = this.props;
    const { userEmail, userPassword } = this.state;
    let errors = 0;

    if (userEmail === "") {
      errors++;
      this.setState({
        errors: {
          userEmail: "Please type your userEmail.",
        },
      });
    } else if (userPassword === "") {
      errors++;
      this.setState({
        errors: {
          userPassword: "Please type your userPassword.",
        },
      });
    }

    if (errors === 0) {
      this.setState({
        isLoading: true,
      });

      try {
        const response = await client.mutate({
          mutation: USER_LOGIN,
          variables: {
            userEmail: userEmail,
            userPassword: userPassword,
          },
        });

        let { data } = response;

        this.setState({
          isLoading: false,
          success: "You are logged in. Please wait...",
        });

        localStorage.setItem("userSellerId", data.userLogin.user.userSellerId);
        localStorage.setItem(
          "userOrders",
          JSON.stringify(data.userLogin.user.userOrders)
        );
        localStorage.setItem("token", data.userLogin.token);

        setTimeout(() => {
          this.props.history.push("/results/0");
        }, 1000);
      } catch (error) {
        this.setState({
          isLoading: false,
          errors: {
            graphQL: error.message,
          },
        });
      }
    }
  };

  updateCart = (action, data) => {
    const inventoryCartList = this.state.inventoryCart;

    switch (action) {
      case "add":
        inventoryCartList.push(data);
        this.setState({
          inventoryCart: inventoryCartList,
        });
        localStorage.setItem(
          "inventoryCart",
          JSON.stringify(inventoryCartList)
        );
        break;
      case "remove":
        const index = inventoryCartList.findIndex((obj) => obj.id === data.id);
        inventoryCartList.splice(index, 1);
        this.setState({
          inventoryCart: inventoryCartList,
        });
        localStorage.setItem(
          "inventoryCart",
          JSON.stringify(inventoryCartList)
        );
        break;
      default:
        break;
    }

    this.orderTotal();
  };

  updateQuantity = (data, value) => {
    const inventoryCartList = this.state.inventoryCart;
    const component = data;
    const index = inventoryCartList.findIndex((obj) => obj.id === component.id);

    inventoryCartList[index].quantity = value;

    this.setState({
      inventoryCart: inventoryCartList,
    });

    localStorage.setItem("inventoryCart", JSON.stringify(inventoryCartList));

    this.orderTotal();
  };

  orderTotal = () => {
    const inventoryCart = JSON.parse(localStorage.getItem("inventoryCart"));
    let total = 0.0;

    inventoryCart.map((component) => {
      total =
        total +
        parseFloat(
          component.resalePrice.replace(",", ".") * component.quantity
        );
      return null;
    });

    this.setState({
      orderTotal: total.toFixed(2),
    });
  };

  closeResult = () => {
    this.setState({
      success: "",
      errors: {
        userEmail: "",
        userPassword: "",
        graphQL: "",
      },
    });
  };

  render() {
    const { history } = this.props;
    const {
      isLoading,
      inventoryCart,
      userEmail,
      userPassword,
      success,
      errors,
    } = this.state;

    return (
      <Fragment>
        {isLoading && <Loading loadingLabel={"Please wait..."} />}

        {(errors.userEmail !== "" ||
          errors.userPassword !== "" ||
          errors.graphQL !== "" ||
          success !== "") && (
          <Result
            resultVariant={
              errors.userEmail !== "" ||
              errors.userPassword !== "" ||
              errors.graphQL !== ""
                ? "error"
                : "success"
            }
            resultText={
              errors.userEmail ||
              errors.userPassword ||
              errors.graphQL ||
              success
            }
            resultOnClick={this.closeResult}
          />
        )}

        <section className="hero is-dark is-fullheight login">
          <div className="hero-head">
            <NavBar
              history={history}
              client={null}
              isLoggedIn={false}
              inventoryData={null}
              inventoryCart={inventoryCart}
              componentDetails={null}
              updateCart={this.updateCart}
              updateQuantity={this.updateQuantity}
            />
          </div>

          <div className="hero-body">
            <div className="container has-text-centered">
              <img className="hero-image" alt={"XStock"} src={defaultAltPng} />

              <div className="columns is-centered">
                <div className="column"></div>
                <div className="column is-half">
                  <h1 className="title">Login</h1>

                  <form onSubmit={(event) => this.onSubmit(event)}>
                    <div className="field">
                      <div className="control">
                        <input
                          id="userEmail"
                          className="input is-dark is-inverted"
                          type="email"
                          value={userEmail}
                          onChange={(event) => this.onChange(event)}
                          placeholder="Email"
                        />
                      </div>
                    </div>

                    <div className="field">
                      <div className="control">
                        <input
                          id="userPassword"
                          className="input is-dark is-inverted"
                          type="password"
                          value={userPassword}
                          onChange={(event) => this.onChange(event)}
                          placeholder="Password"
                        />
                      </div>
                    </div>

                    <div className="field" style={{ marginTop: "30px" }}>
                      <div className="control has-text-centered">
                        <button
                          type="submit"
                          className="button is-info"
                          onClick={(event) => this.onSubmit(event)}
                        >
                          Login
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <Footer history={history} />
        </section>
      </Fragment>
    );
  }
}

export default withApollo(Login);
