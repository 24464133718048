import React, { Fragment } from 'react';

export default function Modal(props) {
  const { modalTitle, modalForm, modalFooter, modalOnClickConfirm, modalOnClickClose } = props;

  return (
    <div className="modal is-active">
		  <div className="modal-background"></div>
		  <div className="modal-card">
		    <header className="modal-card-head">
		      <h2 className="modal-card-title">
		      	{modalTitle}
		      </h2>
		      <button
		      	className="delete"
		      	aria-label="close"
		      	onClick={(event) => modalOnClickClose(event)}
		      ></button>
		    </header>
		    <section className="modal-card-body">
		      {modalForm}
		    </section>
		    <footer className="modal-card-foot">
		    	{modalFooter &&
		    		<Fragment>
		    			{modalFooter}
		    		</Fragment>
		    	}
		      <button
		      	className="button is-info"
		      	onClick={(event) => modalOnClickConfirm(event)}
		      >
		      	Order Now
		      </button>
		    </footer>
		  </div>
		</div>
  );
}