import gql from 'graphql-tag';

export const CREATE_ORDER = gql`
  mutation (
    $customerName: String!
    $customerEmail: String!
    $customerContactNumber: String!
    $customerCompanyName: String
    $customerVATNumber: String
    $customerAddressLineOne: String!
    $customerAddressLineTwo: String
    $customerAddressCity: String!
    $customerAddressPostalCode: String!
    $customerAddressProvince: String!
    $orderNotes: String
    $orderTotal: String
    $orderStatus: String
    $orderPayment: String
  	$input: [OrderInput]
  ) {
	  createOrder(
	    customerName: $customerName
	    customerEmail: $customerEmail
	    customerContactNumber: $customerContactNumber
	    customerCompanyName: $customerCompanyName
	    customerVATNumber: $customerVATNumber
	    customerAddressLineOne: $customerAddressLineOne
	    customerAddressLineTwo: $customerAddressLineTwo
	    customerAddressCity: $customerAddressCity
	    customerAddressPostalCode: $customerAddressPostalCode
	    customerAddressProvince: $customerAddressProvince
	    orderNotes: $orderNotes
	    orderTotal: $orderTotal
	    orderStatus: $orderStatus
	    orderPayment: $orderPayment
	    input: $input
	  ) {
	    id
	  }
	}
`;

export const UPDATE_ORDER = gql`
  mutation (
  	$orderId: ID!
  	$orderUpdate: String!
    $customerName: String
    $customerEmail: String
    $customerContactNumber: String
    $customerCompanyName: String
    $customerVATNumber: String
    $customerAddressLineOne: String
    $customerAddressLineTwo: String
    $customerAddressCity: String
    $customerAddressPostalCode: String
    $customerAddressProvince: String
    $orderNotes: String
    $orderTotal: String
    $orderStatus: String
    $orderPayment: String
  ) {
	  updateOrder(
	  	orderId: $orderId
	  	orderUpdate: $orderUpdate
	    customerName: $customerName
	    customerEmail: $customerEmail
	    customerContactNumber: $customerContactNumber
	    customerCompanyName: $customerCompanyName
	    customerVATNumber: $customerVATNumber
	    customerAddressLineOne: $customerAddressLineOne
	    customerAddressLineTwo: $customerAddressLineTwo
	    customerAddressCity: $customerAddressCity
	    customerAddressPostalCode: $customerAddressPostalCode
	    customerAddressProvince: $customerAddressProvince
	    orderNotes: $orderNotes
	    orderTotal: $orderTotal
	    orderStatus: $orderStatus
	    orderPayment: $orderPayment
	  ) {
	    id
	    customerName
	    customerEmail
	    customerContactNumber
	    customerAddressLineOne
	    customerAddressLineTwo
	    customerAddressCity
	    customerAddressPostalCode
	    customerAddressProvince
	    orderNotes
	    orderTotal
	    orderStatus
	    orderPayment
	    orderComponents {
	      sellerId
			  manufacturerPartNumber
			  manufacturerName
			  partDescription
			  quantityAvailable
			  minimumOrderQuantity
			  orderIncrementPackQuantity
			  dateCodeRange
			  resalePrice
			  quantity
			  sellerCost
			  authorisedChannel
			  leadTime
			  packageType
			  imageLink
			  datasheetLink
	    }
	  }
	}
`;