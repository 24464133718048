import React, { Component, Fragment } from "react";
import { withApollo } from "react-apollo";
import CryptoJS from "crypto-js";

import NavBar from "../components/common/NavBar";
import Footer from "../components/common/Footer";
import Result from "../components/common/Result";

import { CONTACT_REQUEST } from "../graphql/mutations/Navbar";

import defaultAltPng from "../assets/png/default-alt.png";

class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      termsOfUse: false,
      offersUpdates: true,
      searchTerm: "",
      numberOfInventoryData: 0,
      inventoryDataPage: 0,
      inventoryCart: [],
      orderTotal: 0,
      success: "",
      name: "",
      email: "",
      tel: "",
      errors: {
        noResults: "",
        graphQL: "",
        name: "",
        email: "",
        notes: "",
        termsOfUse: "",
      },
    };
  }

  updateCart = (action, data) => {
    const inventoryCartList = this.state.inventoryCart;

    switch (action) {
      case "add":
        inventoryCartList.push(data);
        this.setState({
          inventoryCart: inventoryCartList,
        });
        localStorage.setItem(
          "inventoryCart",
          JSON.stringify(inventoryCartList)
        );
        break;
      case "remove":
        const index = inventoryCartList.findIndex((obj) => obj.id === data.id);
        inventoryCartList.splice(index, 1);
        this.setState({
          inventoryCart: inventoryCartList,
        });
        localStorage.setItem(
          "inventoryCart",
          JSON.stringify(inventoryCartList)
        );
        break;
      default:
        break;
    }

    this.orderTotal();
  };

  updateQuantity = (data, value) => {
    const inventoryCartList = this.state.inventoryCart;
    const component = data;
    const index = inventoryCartList.findIndex((obj) => obj.id === component.id);

    inventoryCartList[index].quantity = value;

    this.setState({
      inventoryCart: inventoryCartList,
    });

    localStorage.setItem("inventoryCart", JSON.stringify(inventoryCartList));

    this.orderTotal();
  };

  onChange = (event) => {
    this.setState({
      success: "",
      errors: {
        graphQL: "",
        name: "",
        email: "",
        notes: "",
        termsOfUse: "",
      },
    });

    this.setState({
      [event.target.id]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    });
  };

  contact = async () => {
    const { client } = this.props;
    const { name, email, notes, offersUpdates } = this.state;

    try {
      await client.mutate({
        mutation: CONTACT_REQUEST,
        variables: {
          fullName: name,
          email: email,
          notes: notes,
          offersUpdates: offersUpdates,
        },
      });

      this.setState({
        isLoading: false,
        isContact: false,
        success:
          "Thank you for contacting us. We will get back to you within 24 hours.",
      });
    } catch (error) {
      this.setState({
        isLoading: false,
        errors: {
          graphQL: error.message,
        },
      });
    }
  };

  onSubmit = (event) => {
    event.preventDefault();

    const { name, email, notes, termsOfUse } = this.state;
    let errors = 0;

    if (name === "") {
      errors++;
      this.setState({
        errors: {
          name: "Please type your name & surname.",
        },
      });
    } else if (email === "") {
      errors++;
      this.setState({
        errors: {
          email: "Please type your email.",
        },
      });
    } else if (notes === "") {
      errors++;
      this.setState({
        errors: {
          notes: "Please type your notes.",
        },
      });
    } else if (!termsOfUse) {
      errors++;
      this.setState({
        errors: {
          termsOfUse: "Please agree to the terms of use.",
        },
      });
    }

    if (errors === 0) {
      this.setState({
        isLoading: true,
        isOrdering: false,
      });

      this.contact();
    }
  };

  closeResult = () => {
    this.setState({
      success: "",
      errors: {
        graphQL: "",
        name: "",
        email: "",
        notes: "",
        termsOfUse: "",
      },
    });
  };

  renderTel = () => {
    const bytes = CryptoJS.AES.decrypt(
      process.env.REACT_APP_TEL,
      process.env.REACT_APP_SECRET_KEY
    );
    const tel = bytes.toString(CryptoJS.enc.Utf8);
    this.setState({
      tel: tel,
    });
  };

  render() {
    const { history } = this.props;
    const {
      termsOfUse,
      offersUpdates,
      name,
      email,
      notes,
      tel,
      inventoryCart,
      success,
      errors,
    } = this.state;

    return (
      <Fragment>
        {(errors.graphQL !== "" ||
          errors.name !== "" ||
          errors.email !== "" ||
          errors.notes !== "" ||
          errors.termsOfUse !== "" ||
          success !== "") && (
          <Result
            resultVariant={
              errors.graphQL !== "" ||
              errors.name !== "" ||
              errors.email !== "" ||
              errors.notes !== "" ||
              errors.termsOfUse !== ""
                ? "error"
                : "success"
            }
            resultText={
              errors.graphQL ||
              errors.name ||
              errors.email ||
              errors.notes ||
              errors.termsOfUse ||
              success
            }
            resultOnClick={this.closeResult}
          />
        )}

        <section className="hero is-dark is-fullheight contact">
          <div className="hero-head">
            <NavBar
              history={history}
              client={null}
              isLoggedIn={false}
              inventoryData={null}
              inventoryCart={inventoryCart}
              componentDetails={null}
              updateCart={this.updateCart}
              updateQuantity={this.updateQuantity}
            />
          </div>

          <div className="hero-body">
            <div className="container">
              <div className="columns">
                <div className="column">
                  <h1 className="title">Send Us A Message</h1>

                  <form onSubmit={(event) => this.onSubmit(event)}>
                    <div className="field">
                      <div className="control">
                        <input
                          id="name"
                          className="input is-dark is-inverted"
                          type="text"
                          value={name}
                          onChange={(event) => this.onChange(event)}
                          placeholder="Name & Surname*"
                        />
                      </div>
                    </div>

                    <div className="field">
                      <div className="control">
                        <input
                          id="email"
                          className="input is-dark is-inverted"
                          type="email"
                          value={email}
                          onChange={(event) => this.onChange(event)}
                          placeholder="Email*"
                        />
                      </div>
                    </div>

                    <div className="field">
                      <div className="control">
                        <textarea
                          id="notes"
                          className="input is-dark is-inverted"
                          value={notes}
                          onChange={(event) => this.onChange(event)}
                          placeholder="Notes*"
                        />
                      </div>
                    </div>

                    <label className="checkbox">
                      <input
                        type="checkbox"
                        id="termsOfUse"
                        checked={termsOfUse}
                        onChange={(event) => this.onChange(event)}
                      />{" "}
                      I agree to the{" "}
                      <a href="/terms" target="_blank">
                        terms of use
                      </a>
                      .
                    </label>

                    <label className="checkbox">
                      <input
                        type="checkbox"
                        id="offersUpdates"
                        checked={offersUpdates}
                        onChange={(event) => this.onChange(event)}
                      />{" "}
                      I would like to receive offers and updates via email.
                    </label>

                    <button
                      className="button is-info"
                      onClick={(event) => this.onSubmit(event)}
                    >
                      Send Now
                    </button>
                  </form>
                </div>

                <div className="column">
                  <h1 className="title">Contact Information</h1>
                  <h2 className="subtitle">
                    Ricolene Vadivelu - Director / Founder
                  </h2>
                  <p>
                    <i className="fas fa-phone"></i>
                    {tel === "" && (
                      <span onClick={() => this.renderTel()}>
                        Show Telephone Number
                      </span>
                    )}
                    {tel !== "" && <a href={`tel:${tel}`}>Tel: {tel}</a>}
                  </p>
                  <iframe
                    title="Location"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3584.2376944374923!2d27.94804555064407!3d-26.058487164312716!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e9575c258bf6d57%3A0x3a7a0cdc0f261a4d!2s2%20Camilla%20Cl%2C%20Northwold%20AH%2C%20Randburg%2C%202162!5e0!3m2!1sen!2sza!4v1668334423308!5m2!1sen!2sza"
                    width="600"
                    height="350"
                    // style="border:0;"
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
          <Footer history={history} />
          <img className="hero-image" alt={"XStock"} src={defaultAltPng} />
        </section>
      </Fragment>
    );
  }
}

export default withApollo(Contact);
