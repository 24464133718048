import React, { Component, Fragment } from "react";
import { withApollo } from "react-apollo";

import Loading from "../components/common/Loading";
import Result from "../components/common/Result";
import NavBar from "../components/common/NavBar";
import Footer from "../components/common/Footer";
import ResultsTable from "../components/common/ResultsTable";

import {
  GET_NUMBER_OF_COMPONENTS,
  GET_USER_COMPONENTS,
  GET_ALL_COMPONENTS,
  GET_NUMBER_OF_SEARCH_COMPONENTS,
  SEARCH_COMPONENTS,
} from "../graphql/queries/Components";
import {
  IMPORT_COMPONENTS,
  DELETE_ALL_COMPONENTS,
  DELETE_COMPONENT,
} from "../graphql/mutations/Results";

import { authenticateSession, parseCSVToJSON } from "../utils";

class Results extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isLoggedIn: false,
      numberOfInventoryData: 0,
      inventoryDataPage: 0,
      inventoryData: [],
      inventoryDataSecions: 0,
      inventoryCart: [],
      orderTotal: 0,
      success: "",
      errors: {
        graphQL: "",
      },
    };
  }

  getNumberOfComponents = async () => {
    const { client } = this.props;

    try {
      const response = await client.query({
        query: GET_NUMBER_OF_COMPONENTS,
      });

      let { data } = response;

      this.setState({
        numberOfInventoryData: data.getNumberOfComponents.value,
        inventoryDataSecions: data.getNumberOfComponents.value / 100,
      });
    } catch (error) {
      console.log(error);
    }
  };

  getAllComponents = async (inventoryDataPage) => {
    const { client } = this.props;

    try {
      const response = await client.query({
        query: GET_ALL_COMPONENTS,
        variables: {
          page: inventoryDataPage,
        },
      });

      let { data } = response;

      this.setState({
        isLoading: false,
        inventoryDataPage: inventoryDataPage,
        inventoryData: data.getAllComponents,
      });
    } catch (error) {
      console.log(error);
    }
  };

  getUserComponents = async (inventoryDataPage) => {
    const { client } = this.props;

    try {
      const response = await client.query({
        query: GET_USER_COMPONENTS,
        variables: {
          page: inventoryDataPage,
        },
      });

      let { data } = response;

      this.setState({
        isLoading: false,
        inventoryDataPage: inventoryDataPage,
        inventoryData: data.getUserComponents,
      });
    } catch (error) {
      console.log(error);
    }
  };

  componentDidMount() {
    const { match } = this.props;
    const { isLoggedIn } = this.state;
    const userSearch = document.getElementById("userSearch");
    const inventoryCart = JSON.parse(localStorage.getItem("inventoryCart"));

    this.setState({
      isLoading: true,
    });

    if (isNaN(parseInt(match.params.page))) {
      this.getNumberOfComponents();
      this.getAllComponents(0);
      this.props.history.push("/results/0");
    } else {
      if (match.params.search) {
        userSearch.value = match.params.search;
        this.filterInventory(match.params.search);
      } else {
        if (isLoggedIn) {
          this.getUserComponents(parseInt(match.params.page));
        } else {
          this.getNumberOfComponents();
          this.getAllComponents(parseInt(match.params.page));
        }
      }

      if (inventoryCart) {
        this.setState({
          inventoryCart: inventoryCart,
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { match } = this.props;

    if (!match.params.search) {
      const isLoggedIn = authenticateSession();

      if (prevState.isLoggedIn !== isLoggedIn) {
        this.setState({
          isLoggedIn: isLoggedIn,
        });
      }
    }
  }

  import = async (parsedCSV) => {
    const { client } = this.props;
    const userSellerId = localStorage.getItem("userSellerId");

    try {
      const response = await client.mutate({
        mutation: IMPORT_COMPONENTS,
        variables: {
          userSellerId: userSellerId,
          input: parsedCSV,
        },
      });

      let { data } = response;

      this.setState({
        isLoading: false,
        inventoryData: data.importComponents,
        success: "Data has been uploaded.",
      });
    } catch (error) {
      this.setState({
        isLoading: false,
        errors: {
          graphQL: error.message,
        },
      });
    }
  };

  importInventory = (event) => {
    const csvData = event.target.files[0];

    this.setState({
      isLoading: true,
    });

    const csvReader = new FileReader();
    csvReader.readAsText(csvData);
    csvReader.onload = function (e) {
      let result = JSON.parse(parseCSVToJSON(e.target.result));

      this.import(result);
    }.bind(this);
  };

  deleteAllComponents = async () => {
    const { client } = this.props;
    const { inventoryData } = this.state;

    this.setState({
      isLoading: true,
    });

    try {
      const response = await client.mutate({
        mutation: DELETE_ALL_COMPONENTS,
        variables: {
          input: inventoryData,
        },
      });

      let { data } = response;

      this.getUserComponents();

      this.setState({
        isLoading: false,
        success: data.deleteAllComponents.success,
      });
    } catch (error) {
      console.log(error);
    }
  };

  delete = async (id) => {
    const { client } = this.props;

    try {
      const response = await client.mutate({
        mutation: DELETE_COMPONENT,
        variables: {
          id: id,
        },
      });

      let { data } = response;

      this.getUserComponents();

      this.setState({
        isLoading: false,
        success: data.deleteComponents.success,
      });
    } catch (error) {
      console.log(error);
    }
  };

  deleteComponent = (event, id) => {
    this.setState({
      isLoading: true,
    });

    this.delete(id);
  };

  getNumberOfSearchComponents = async (value) => {
    const { client } = this.props;

    try {
      const response = await client.query({
        query: GET_NUMBER_OF_SEARCH_COMPONENTS,
        variables: {
          search: value,
        },
      });

      let { data } = response;

      this.setState({
        numberOfInventoryData: data.getNumberOfSearchComponents.value,
        inventoryDataSecions: data.getNumberOfSearchComponents.value / 100,
      });
    } catch (error) {
      console.log(error);
    }
  };

  search = async (value, inventoryDataPage) => {
    const { client } = this.props;

    try {
      const response = await client.query({
        query: SEARCH_COMPONENTS,
        variables: {
          page: inventoryDataPage ? inventoryDataPage : 0,
          search: value,
        },
      });

      let { data } = response;

      this.setState({
        isLoading: false,
        inventoryData: data.searchComponents,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
        errors: {
          graphQL: error.message,
        },
      });
    }
  };

  searchInventory = (event) => {
    const value = event.target.value;

    if (value !== "") {
      this.search(value);
    } else {
      this.getNumberOfComponents();
      this.getAllComponents(0);
      this.props.history.push("/results/0");
    }
  };

  filterInventory = (value) => {
    if (value !== "") {
      this.getNumberOfSearchComponents(value);
      this.search(value);
    } else {
      this.getNumberOfComponents();
      this.getAllComponents(0);
      this.props.history.push("/results/0");
    }
  };

  updateCart = (action, data) => {
    const inventoryCartList = this.state.inventoryCart;
    const component = data;

    switch (action) {
      case "add":
        component.quantity = 1;
        inventoryCartList.push(component);
        this.setState({
          inventoryCart: inventoryCartList,
        });
        localStorage.setItem(
          "inventoryCart",
          JSON.stringify(inventoryCartList)
        );
        break;
      case "remove":
        const index = inventoryCartList.findIndex(
          (obj) => obj.id === component.id
        );
        inventoryCartList.splice(index, 1);
        this.setState({
          inventoryCart: inventoryCartList,
        });
        localStorage.setItem(
          "inventoryCart",
          JSON.stringify(inventoryCartList)
        );
        break;
      default:
        break;
    }

    this.orderTotal();
  };

  updateQuantity = (data, value) => {
    const inventoryCartList = this.state.inventoryCart;
    const component = data;
    const index = inventoryCartList.findIndex((obj) => obj.id === component.id);

    inventoryCartList[index].quantity = value;

    this.setState({
      inventoryCart: inventoryCartList,
    });

    localStorage.setItem("inventoryCart", JSON.stringify(inventoryCartList));

    this.orderTotal();
  };

  orderTotal = () => {
    const inventoryCart = JSON.parse(localStorage.getItem("inventoryCart"));
    let total = 0.0;

    inventoryCart.map((component) => {
      total =
        total +
        parseFloat(
          component.resalePrice.replace(",", ".") * component.quantity
        );
      return null;
    });

    this.setState({
      orderTotal: total.toFixed(2),
    });
  };

  goToSection = (direction) => {
    const { match } = this.props;
    const { inventoryDataPage } = this.state;

    this.setState({
      isLoading: true,
    });

    if (direction === "next") {
      this.setState({
        inventoryDataPage: inventoryDataPage + 1,
      });

      if (match.params.search) {
        this.search(match.params.search, inventoryDataPage + 1);
        this.props.history.push(
          `/results/${inventoryDataPage + 1}/${match.params.search}`
        );
      } else {
        this.getAllComponents(inventoryDataPage + 1);
        this.props.history.push(`/results/${inventoryDataPage + 1}`);
      }
    } else if (direction === "previous" && inventoryDataPage > 0) {
      this.setState({
        inventoryDataPage: inventoryDataPage - 1,
      });
      this.getAllComponents(inventoryDataPage - 1);
      this.props.history.push(`/results/${inventoryDataPage - 1}`);
    } else {
      this.setState({
        isLoading: false,
      });
    }
  };

  closeResult = () => {
    this.setState({
      success: "",
      errors: {
        graphQL: "",
      },
    });
  };

  render() {
    const { history, match, client } = this.props;
    const {
      isLoading,
      isLoggedIn,
      inventoryDataPage,
      inventoryData,
      inventoryDataSecions,
      inventoryCart,
      success,
      errors,
    } = this.state;

    return (
      <Fragment>
        {isLoading && <Loading loadingLabel={"Please wait..."} />}

        {(errors.graphQL !== "" || success !== "") && (
          <Result
            resultVariant={errors.graphQL !== "" ? "error" : "success"}
            resultText={errors.graphQL || success}
            resultOnClick={this.closeResult}
          />
        )}

        <section className="hero is-dark is-fullheight results">
          <div className="hero-head">
            <NavBar
              history={history}
              client={client}
              isLoggedIn={isLoggedIn}
              inventoryData={inventoryData}
              inventoryCart={inventoryCart}
              importInventory={(event) => this.importInventory(event)}
              componentDetails={null}
              deleteAllComponents={(event) => this.deleteAllComponents(event)}
              updateCart={this.updateCart}
              updateQuantity={this.updateQuantity}
            />
          </div>

          <div className="hero-body">
            <div className="container has-text-centered">
              <h1 className="title">{isLoggedIn ? "Inventory" : "Results"}</h1>

              <nav
                className="pagination is-centered"
                role="navigation"
                aria-label="pagination"
              >
                <button
                  className="pagination-previous button is-dark"
                  onClick={() => this.goToSection("previous")}
                >
                  Previous
                </button>
                <button
                  className="pagination-next button is-dark"
                  onClick={() => this.goToSection("next")}
                >
                  Next page
                </button>
                <div className="pagination-list">
                  <p>
                    {inventoryDataPage + 1} of{" "}
                    {Math.round(inventoryDataSecions)}
                  </p>
                </div>
              </nav>

              <div className="field">
                <p className="control has-icons-left">
                  <input
                    id="userSearch"
                    className="input is-dark is-inverted"
                    type="text"
                    placeholder="Search..."
                    onChange={(event) => this.searchInventory(event)}
                  />
                  <span className="icon is-small is-left">
                    <i className="fas fa-search" />
                  </span>
                </p>
              </div>

              {inventoryData.length > 0 && (
                <ResultsTable
                  history={history}
                  match={match}
                  isLoggedIn={isLoggedIn}
                  tableData={inventoryData}
                  tableCart={inventoryCart}
                  tableUpdateCart={this.updateCart}
                  deleteComponent={(event, id) =>
                    this.deleteComponent(event, id)
                  }
                />
              )}
            </div>
          </div>
          <Footer history={history} />
        </section>
      </Fragment>
    );
  }
}

export default withApollo(Results);
