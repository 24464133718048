import gql from 'graphql-tag';

export const USER_REGISTER = gql`
  mutation (
  	$userName: String!
  	$userEmail: String!
  	$userPassword: String!
  	$userSellerId: String!
  ) {
	  userRegister(
	    userName: $userName
	    userEmail: $userEmail
	    userPassword: $userPassword
	    userSellerId: $userSellerId
	  ) {
	    user {
	      id
	    }
	  }
	}
`;