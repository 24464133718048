import React, { Fragment } from 'react';

import { svgAssetStyles } from '../../utils';

import addSvg from '../../assets/svg/add.svg';
import timesSvg from '../../assets/svg/times.svg';

export default function CartTable (props) {
  const { history, tablePayable, tableCart, tableUpdateCart, tableUpdateQuantity, deleteComponent, isLoggedIn } = props;

  return (
    <table className="table is-fullwidth is-hoverable is-inverted is-bordered is-striped is-cart">
		  <thead>
		    <tr>
				  <th>Manufacturer Part Number</th>
				  <th>Manufacturer Name</th>
				  <th>Part Description</th>
				  {!tablePayable &&
				  	<th>Price</th>
				  }
				  <th>Quantity</th>
				  <th>Total</th>
				  {/*<th>Ships In</th>*/}
				  {!tablePayable &&
				  	<th></th>
				  }
		    </tr>
		  </thead>
		  <tbody>
		  	{tableCart.length > 0 && tableCart.map((data, index) => {
		  		const dataIndex = tableCart.findIndex(obj => obj.id === data.id);

		  		return (
		  			<tr
		  				key={index}
		  			>
		  				<td>
		  					{data.manufacturerPartNumber}
		  				</td>
		  				<td>
		  					{data.manufacturerName}
		  				</td>
		  				<td>
		  					{data.partDescription}
		  				</td>
		  				{!tablePayable &&
		  					<td>
			  					R {parseFloat(data.resalePrice.replace(",", ".")).toFixed(2)}
			  				</td>
		  				}
		  				<td>
		  					{tablePayable &&
		  						<Fragment>
		  							{data.quantity}
		  						</Fragment>
		  					}

		  					{!tablePayable &&
		  						<input
	                  id={index}
	                  className="input is-dark is-inverted"
	                  type="number"
	                  min={1}
	                  max={parseInt(data.quantityAvailable)}
	                  value={data.quantity}
	                  onChange={(event) => tableUpdateQuantity(data, event.target.value)}
	                />
		  					}
		  				</td>
		  				<td>
		  					R {parseFloat(data.resalePrice.replace(",", ".") * data.quantity).toFixed(2)}
		  				</td>
		  				{/*<td>
		  					{convertToShippingDays(data.leadTime)}
		  				</td>*/}
		  				{!tablePayable &&
		  					<td>
			  					<div className="field has-addons">
									  <p className="control">
									    <button
									    	className="button is-dark is-half"
									    	onClick={() => history.push(`/details/${data.id}`)}
									    >
									      <span>View Details</span>
									    </button>
									  </p>

									  {dataIndex === -1 &&
									  	<p className="control">
										    <button
										    	className="button is-info is-half"
										    	onClick={() => tableUpdateCart("add", data)}
										    >
									      	<img
					                  src={addSvg}
					                  alt="Add to Cart"
					                  style={svgAssetStyles("2px", "5px", "0px", null, "12px", "1", null)}
					                /> Cart
										    </button>
										  </p>
									  }

									  {dataIndex > -1 &&
									  	<p className="control">
										    <button
										    	className="button is-danger is-half"
										    	onClick={() => tableUpdateCart("remove", data)}
										    >
										      <img
					                  src={timesSvg}
					                  alt="Remove from Cart"
					                  style={svgAssetStyles("2px", "5px", "0px", null, "10px", "1", null)}
					                /> Cart
										    </button>
										  </p>
									  }

									  {isLoggedIn &&
									  	<p className="control">
										    <button
										    	className="button is-dark is-half"
										    	onClick={(event, id) => deleteComponent(event, data.id)}
										    >
										      <span>Delete</span>
										    </button>
										  </p>
									  }
									</div>
			  				</td>
		  				}
		  			</tr>
		  		)
		  	})}

		  	{tableCart.length === 0 &&
		  		<tr>
			  		<td colSpan={5}>No data</td>
			  	</tr>
		  	}
		  </tbody>
		</table>
  );
}