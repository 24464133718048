import React, { Component, Fragment } from "react";
import { withApollo } from "react-apollo";
import crypto from "crypto";

import Loading from "../components/common/Loading";
import Result from "../components/common/Result";
import NavBar from "../components/common/NavBar";
import Footer from "../components/common/Footer";
import Modal from "../components/common/Modal";
import CartTable from "../components/common/CartTable";

import {
  GET_USER_COMPONENTS,
  GET_ALL_COMPONENTS,
} from "../graphql/queries/Components";
import { GET_ORDER } from "../graphql/queries/Orders";
import { CREATE_ORDER, UPDATE_ORDER } from "../graphql/mutations/Checkout";

class Checkout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isLoggedIn: false,
      isOrdering: false,
      inventoryData: [],
      inventoryCart: [],
      orderToPay: "",
      orderTotal: 0,
      termsOfUse: false,
      customerName: "",
      customerEmail: "",
      customerContactNumber: "",
      customerCompanyName: "",
      customerVATNumber: "",
      customerAddressLineOne: "",
      customerAddressLineTwo: "",
      customerAddressCity: "",
      customerAddressPostalCode: "",
      customerAddressProvince: "",
      success: "",
      errors: {
        graphQL: "",
        customerName: "",
        customerEmail: "",
        customerContactNumber: "",
        customerAddressLineOne: "",
        customerAddressCity: "",
        customerAddressPostalCode: "",
        customerAddressProvince: "",
        termsOfUse: "",
      },
    };
  }

  getAllComponents = async () => {
    const { client } = this.props;

    try {
      const response = await client.query({
        query: GET_ALL_COMPONENTS,
      });

      let { data } = response;

      this.setState({
        isLoading: false,
        inventoryData: data.getAllComponents,
      });
    } catch (error) {
      console.log(error);
    }
  };

  getUserComponents = async () => {
    const { client } = this.props;

    try {
      const response = await client.query({
        query: GET_USER_COMPONENTS,
      });

      let { data } = response;

      this.setState({
        isLoading: false,
        inventoryData: data.getUserComponents,
      });
    } catch (error) {
      console.log(error);
    }
  };

  orderPaid() {
    this.setState({
      isLoading: false,
      success:
        "Thank you for your payment, we will email you the delivery details of your order.",
    });

    setTimeout(() => {
      this.props.history.push("/");
    }, 2500);
  }

  async componentDidMount() {
    const { match, client } = this.props;
    const inventoryCart = JSON.parse(localStorage.getItem("inventoryCart"));
    const id = match.params.id;

    this.setState({
      isLoading: true,
    });

    if (id && match.url.indexOf("success") === -1) {
      try {
        const response = await client.query({
          query: GET_ORDER,
          variables: {
            id: id,
          },
        });

        let { data } = response;

        this.setState({
          isLoading: false,
          orderToPay: data.getOrder,
        });
      } catch (error) {
        console.log(error);
      }
    } else if (id && match.url.indexOf("success") > -1) {
      try {
        await client.mutate({
          mutation: UPDATE_ORDER,
          variables: {
            orderId: id,
            orderUpdate: "paid",
            orderPayment: "Paid",
          },
        });

        this.orderPaid();
      } catch (error) {
        console.log(error);
      }
    } else if (inventoryCart) {
      this.setState({
        isLoading: false,
        inventoryCart: inventoryCart,
      });

      this.orderTotal();
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  updateCart = (action, data) => {
    const inventoryCartList = this.state.inventoryCart;
    const component = data;

    switch (action) {
      case "add":
        component.quantity = 1;
        inventoryCartList.push(component);
        this.setState({
          inventoryCart: inventoryCartList,
        });
        localStorage.setItem(
          "inventoryCart",
          JSON.stringify(inventoryCartList)
        );
        break;
      case "remove":
        const index = inventoryCartList.findIndex(
          (obj) => obj.id === component.id
        );
        inventoryCartList.splice(index, 1);
        this.setState({
          inventoryCart: inventoryCartList,
        });
        localStorage.setItem(
          "inventoryCart",
          JSON.stringify(inventoryCartList)
        );
        break;
      default:
        break;
    }

    if (inventoryCartList.length > 0) {
      this.orderTotal();
    } else {
      this.props.history.push("/results/0");
    }
  };

  updateQuantity = (data, value) => {
    const inventoryCartList = this.state.inventoryCart;
    const component = data;
    const index = inventoryCartList.findIndex((obj) => obj.id === component.id);

    inventoryCartList[index].quantity = value;

    this.setState({
      inventoryCart: inventoryCartList,
    });

    localStorage.setItem("inventoryCart", JSON.stringify(inventoryCartList));

    this.orderTotal();
  };

  orderTotal = () => {
    const inventoryCart = JSON.parse(localStorage.getItem("inventoryCart"));
    let total = 0.0;

    inventoryCart.map((component) => {
      total =
        total +
        parseFloat(
          component.resalePrice.replace(",", ".") * component.quantity
        );
      return null;
    });

    this.setState({
      orderTotal: total.toFixed(2),
    });
  };

  onChange = (event) => {
    this.setState({
      success: "",
      errors: {
        graphQL: "",
        customerName: "",
        customerEmail: "",
        customerContactNumber: "",
        customerAddressLineOne: "",
        customerAddressCity: "",
        customerAddressPostalCode: "",
        customerAddressProvince: "",
        termsOfUse: "",
      },
    });

    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  orderComplete = () => {
    localStorage.removeItem("inventoryCart");
    this.setState({
      isLoading: false,
      success:
        "Order placed. We will get back to you within 24 hours, please wait...",
    });
    setTimeout(() => {
      this.props.history.push("/results/0");
    }, 2500);
  };

  order = async () => {
    const { client } = this.props;
    const {
      customerName,
      customerEmail,
      customerContactNumber,
      customerCompanyName,
      customerVATNumber,
      customerAddressLineOne,
      customerAddressLineTwo,
      customerAddressCity,
      customerAddressPostalCode,
      customerAddressProvince,
      notes,
      orderTotal,
    } = this.state;

    let inventoryCart = JSON.parse(localStorage.getItem("inventoryCart"));
    let orderNotes = notes ? notes : "";

    for (let i = 0; i < inventoryCart.length; i++) {
      delete inventoryCart[i].__typename;
      inventoryCart[i].componentId = inventoryCart[i].id;
      inventoryCart[i].quantity = inventoryCart[i].quantity.toString();
    }

    try {
      await client.mutate({
        mutation: CREATE_ORDER,
        variables: {
          customerName: customerName,
          customerEmail: customerEmail,
          customerContactNumber: customerContactNumber,
          customerCompanyName: customerCompanyName,
          customerVATNumber: customerVATNumber,
          customerAddressLineOne: customerAddressLineOne,
          customerAddressLineTwo: customerAddressLineTwo,
          customerAddressCity: customerAddressCity,
          customerAddressPostalCode: customerAddressPostalCode,
          customerAddressProvince: customerAddressProvince,
          orderNotes: orderNotes,
          orderTotal: "R " + orderTotal,
          orderStatus: "Unconfirmed",
          orderPayment: "Not Paid",
          input: inventoryCart,
        },
      });

      this.orderComplete();
    } catch (error) {
      console.log(error);
    }
  };

  onSubmit = (event) => {
    event.preventDefault();

    const {
      customerName,
      customerEmail,
      customerContactNumber,
      customerAddressLineOne,
      customerAddressCity,
      customerAddressPostalCode,
      customerAddressProvince,
      termsOfUse,
    } = this.state;
    let errors = 0;

    if (customerName === "") {
      errors++;
      this.setState({
        errors: {
          customerName: "Please type your name & surname.",
        },
      });
    } else if (customerEmail === "") {
      errors++;
      this.setState({
        errors: {
          customerEmail: "Please type your email.",
        },
      });
    } else if (customerContactNumber === "") {
      errors++;
      this.setState({
        errors: {
          customerContactNumber: "Please type your contact number.",
        },
      });
    } else if (customerAddressLineOne === "") {
      errors++;
      this.setState({
        errors: {
          customerAddressLineOne: "Please type your address.",
        },
      });
    } else if (customerAddressCity === "") {
      errors++;
      this.setState({
        errors: {
          customerAddressCity: "Please type your city.",
        },
      });
    } else if (customerAddressPostalCode === "") {
      errors++;
      this.setState({
        errors: {
          customerAddressPostalCode: "Please type your postal code.",
        },
      });
    } else if (customerAddressProvince === "") {
      errors++;
      this.setState({
        errors: {
          customerAddressProvince: "Please type your province.",
        },
      });
    } else if (!termsOfUse) {
      errors++;
      this.setState({
        errors: {
          termsOfUse: "Please accept the terms of use.",
        },
      });
    }

    if (errors === 0) {
      this.setState({
        isLoading: true,
        isOrdering: false,
      });

      this.order();
    }
  };

  generateSignature = (data, passPhrase = null) => {
    // Create parameter string
    let pfOutput = "";
    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        if (data[key] !== "") {
          pfOutput += `${key}=${encodeURIComponent(data[key].trim()).replace(
            /%20/g,
            " + "
          )}&`;
        }
      }
    }

    // Remove last ampersand
    let getString = pfOutput.slice(0, -1);
    if (passPhrase !== null) {
      getString += `&passphrase=${encodeURIComponent(passPhrase.trim()).replace(
        /%20/g,
        "+"
      )}`;
    }

    return crypto.createHash("md5").update(getString).digest("hex");
  };

  paymentForm = (orderToPay) => {
    const formData = [];
    formData["merchant_id"] = process.env.REACT_APP_PAYFAST_MERCHANT_ID;
    formData["merchant_key"] = process.env.REACT_APP_PAYFAST_MERCHANT_KEY;
    formData["return_url"] =
      process.env.NODE_ENV === "development"
        ? `https://www.yoursite.com/order/success/${orderToPay.id}`
        : `${process.env.REACT_APP_CLIENT_URL}/order/success/${orderToPay.id}`;
    formData["cancel_url"] =
      process.env.NODE_ENV === "development"
        ? `https://www.yoursite.com/`
        : `${process.env.REACT_APP_CLIENT_URL}`;
    formData["amount"] = parseFloat(
      orderToPay && orderToPay.orderTotal.replace("R", "")
    ).toFixed(2);
    formData["item_name"] = orderToPay.id;
    formData["signature"] = this.generateSignature(formData);

    let htmlForm = `<form action=${process.env.REACT_APP_PAYFAST_URL} method="post">`;
    for (let key in formData) {
      if (formData.hasOwnProperty(key)) {
        let value = formData[key];
        if (value !== "") {
          htmlForm += `<input name="${key}" type="hidden" value="${value.trim()}" />`;
        }
      }
    }

    htmlForm +=
      "<button class='button is-info is-half is-medium' type='submit'>Pay Now</button></form>";
    return htmlForm;
  };

  orderDetails = () => {
    this.setState({
      isOrdering: true,
    });
  };

  closeOrderDetails = (event) => {
    this.setState({
      isOrdering: false,
    });
  };

  closeResult = () => {
    this.setState({
      success: "",
      errors: {
        graphQL: "",
        customerName: "",
        customerEmail: "",
        customerContactNumber: "",
        customerAddressLineOne: "",
        customerAddressCity: "",
        customerAddressPostalCode: "",
        customerAddressProvince: "",
        termsOfUse: "",
      },
    });
  };

  render() {
    const { history, client } = this.props;
    const {
      isLoading,
      isLoggedIn,
      isOrdering,
      inventoryData,
      inventoryCart,
      orderToPay,
      orderTotal,
      customerName,
      customerEmail,
      customerContactNumber,
      customerCompanyName,
      customerVATNumber,
      customerAddressLineOne,
      customerAddressLineTwo,
      customerAddressCity,
      customerAddressPostalCode,
      customerAddressProvince,
      notes,
      termsOfUse,
      success,
      errors,
    } = this.state;

    const modalForm = (
      <form onSubmit={(event) => this.onSubmit(event)}>
        <div className="field">
          <div className="control">
            <input
              id="customerName"
              className="input is-dark is-inverted"
              type="text"
              value={customerName}
              onChange={(event) => this.onChange(event)}
              placeholder="Name & Surname*"
            />
          </div>
        </div>

        <div className="field">
          <div className="control">
            <input
              id="customerEmail"
              className="input is-dark is-inverted"
              type="email"
              value={customerEmail}
              onChange={(event) => this.onChange(event)}
              placeholder="Email*"
            />
          </div>
        </div>

        <div className="field">
          <div className="control">
            <input
              id="customerContactNumber"
              className="input is-dark is-inverted"
              type="text"
              value={customerContactNumber}
              onChange={(event) => this.onChange(event)}
              placeholder="Contact Number*"
            />
          </div>
        </div>

        <div className="field">
          <div className="control">
            <input
              id="customerCompanyName"
              className="input is-dark is-inverted"
              type="text"
              value={customerCompanyName}
              onChange={(event) => this.onChange(event)}
              placeholder="Company Name"
            />
          </div>
        </div>

        <div className="field">
          <div className="control">
            <input
              id="customerVATNumber"
              className="input is-dark is-inverted"
              type="text"
              value={customerVATNumber}
              onChange={(event) => this.onChange(event)}
              placeholder="VAT Number"
            />
          </div>
        </div>

        <div className="field">
          <div className="control">
            <input
              id="customerAddressLineOne"
              className="input is-dark is-inverted"
              type="text"
              value={customerAddressLineOne}
              onChange={(event) => this.onChange(event)}
              placeholder="Address Line 1*"
            />
          </div>
        </div>

        <div className="field">
          <div className="control">
            <input
              id="customerAddressLineTwo"
              className="input is-dark is-inverted"
              type="text"
              value={customerAddressLineTwo}
              onChange={(event) => this.onChange(event)}
              placeholder="Address Line 2"
            />
          </div>
        </div>

        <div className="field">
          <div className="control">
            <input
              id="customerAddressCity"
              className="input is-dark is-inverted"
              type="text"
              value={customerAddressCity}
              onChange={(event) => this.onChange(event)}
              placeholder="City*"
            />
          </div>
        </div>

        <div className="field">
          <div className="control">
            <input
              id="customerAddressPostalCode"
              className="input is-dark is-inverted"
              type="text"
              value={customerAddressPostalCode}
              onChange={(event) => this.onChange(event)}
              placeholder="Postal Code*"
            />
          </div>
        </div>

        <div className="field">
          <div className="control">
            <input
              id="customerAddressProvince"
              className="input is-dark is-inverted"
              type="text"
              value={customerAddressProvince}
              onChange={(event) => this.onChange(event)}
              placeholder="Province*"
            />
          </div>
        </div>

        <div className="field">
          <div className="control">
            <textarea
              id="notes"
              className="input is-dark is-inverted"
              value={notes}
              onChange={(event) => this.onChange(event)}
              placeholder="Order Notes"
            />
          </div>
        </div>
      </form>
    );

    return (
      <Fragment>
        {isLoading && <Loading loadingLabel={"Please wait..."} />}

        {(errors.graphQL !== "" ||
          errors.customerName !== "" ||
          errors.customerEmail !== "" ||
          errors.customerContactNumber !== "" ||
          errors.customerAddressLineOne !== "" ||
          errors.customerAddressCity !== "" ||
          errors.customerAddressPostalCode !== "" ||
          errors.customerAddressProvince !== "" ||
          errors.termsOfUse !== "" ||
          success !== "") && (
          <Result
            resultVariant={
              errors.graphQL !== "" ||
              errors.customerName !== "" ||
              errors.customerEmail !== "" ||
              errors.customerContactNumber !== "" ||
              errors.customerAddressLineOne !== "" ||
              errors.customerAddressCity !== "" ||
              errors.customerAddressPostalCode !== "" ||
              errors.customerAddressProvince !== "" ||
              errors.termsOfUse !== ""
                ? "error"
                : "success"
            }
            resultText={
              errors.graphQL ||
              errors.customerName ||
              errors.customerEmail ||
              errors.customerContactNumber ||
              errors.customerAddressLineOne ||
              errors.customerAddressCity ||
              errors.customerAddressPostalCode ||
              errors.customerAddressProvince ||
              errors.termsOfUse ||
              success
            }
            resultOnClick={this.closeResult}
          />
        )}

        <section className="hero is-dark is-fullheight checkout">
          <div className="hero-head">
            <NavBar
              history={history}
              client={client}
              isLoggedIn={isLoggedIn}
              inventoryData={inventoryData}
              inventoryCart={inventoryCart}
              importInventory={(event) => this.importInventory(event)}
              componentDetails={null}
              deleteAllComponents={(event) => this.deleteAllComponents(event)}
              updateCart={this.updateCart}
              updateQuantity={this.updateQuantity}
            />
          </div>

          <div className="hero-body">
            <div className="container has-text-centered">
              <h1 className="title">
                {orderToPay ? "Order" : "Cart Checkout"}
              </h1>

              {!orderToPay && (
                <button
                  className="button is-info is-half is-medium"
                  onClick={() => this.orderDetails()}
                  disabled={parseFloat(orderTotal) > 500 ? false : true}
                >
                  Place Order
                </button>
              )}

              {orderToPay && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.paymentForm(orderToPay),
                  }}
                />
              )}

              {parseFloat(orderTotal) < 500 && (
                <p>Orders need to R500.00 or more</p>
              )}

              <h2 className="subtitle">
                Total: {orderToPay ? orderToPay.orderTotal : `R ${orderTotal}`}
              </h2>

              <CartTable
                history={history}
                isLoggedIn={isLoggedIn}
                tablePayable={orderToPay ? true : false}
                tableCart={
                  orderToPay ? orderToPay.orderComponents : inventoryCart
                }
                tableUpdateCart={this.updateCart}
                tableUpdateQuantity={this.updateQuantity}
                deleteComponent={(event, id) => this.deleteComponent(event, id)}
              />

              {isOrdering && (
                <Modal
                  modalTitle={"Order Details"}
                  modalForm={modalForm}
                  modalFooter={
                    <label className="checkbox">
                      <input
                        type="checkbox"
                        id="termsOfUse"
                        value={termsOfUse}
                        onChange={(event) => this.onChange(event)}
                      />{" "}
                      I agree to the{" "}
                      <a href="/terms" target="_blank">
                        terms of use
                      </a>
                      .
                    </label>
                  }
                  modalOnClickConfirm={this.onSubmit}
                  modalOnClickClose={this.closeOrderDetails}
                />
              )}
            </div>
          </div>
          <Footer history={history} />
        </section>
      </Fragment>
    );
  }
}

export default withApollo(Checkout);
