import gql from 'graphql-tag';

export const GET_ORDER = gql`
  query (
  	$id: String!
  ) {
    getOrder (
    	id: $id
    ) {
    	id
      customerName
	    customerEmail
	    customerContactNumber
	    customerAddressLineOne
	    customerAddressLineTwo
	    customerAddressCity
	    customerAddressPostalCode
	    customerAddressProvince
	    orderNotes
	    orderTotal
	    orderStatus
	    orderPayment
	    orderComponents {
	    	sellerId
			  manufacturerPartNumber
			  manufacturerName
			  partDescription
			  quantityAvailable
			  minimumOrderQuantity
			  orderIncrementPackQuantity
			  dateCodeRange
			  resalePrice
			  quantity
			  sellerCost
			  authorisedChannel
			  leadTime
			  packageType
			  imageLink
			  datasheetLink
	    }
    }
  }
`;