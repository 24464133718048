import React, { Component, Fragment } from "react";
import { Switch, Route } from "react-router-dom";
import { withApollo } from "react-apollo";

import Default from "./views/Default";
import Register from "./views/Register";
import Login from "./views/Login";
import Results from "./views/Results";
import Details from "./views/Details";
import Checkout from "./views/Checkout";
import CreateComponent from "./views/CreateComponent";
import Terms from "./views/Terms";
import Contact from "./views/Contact";

class App extends Component {
  render() {
    return (
      <Fragment>
        <Switch>
          <Route exact path="/" render={(props) => <Default {...props} />} />
          <Route
            exact
            path="/register"
            render={(props) => <Register {...props} />}
          />
          <Route exact path="/login" render={(props) => <Login {...props} />} />
          <Route
            exact
            path="/results/:page"
            render={(props) => <Results {...props} />}
          />
          <Route
            exact
            path="/results/:page/:search"
            render={(props) => <Results {...props} />}
          />
          <Route
            exact
            path="/details/:id"
            render={(props) => <Details {...props} />}
          />
          <Route
            exact
            path="/details/:id/:search"
            render={(props) => <Details {...props} />}
          />
          <Route
            exact
            path="/checkout"
            render={(props) => <Checkout {...props} />}
          />
          <Route
            exact
            path="/order/:id"
            render={(props) => <Checkout {...props} />}
          />
          <Route
            exact
            path="/order/success/:id"
            render={(props) => <Checkout {...props} />}
          />
          <Route
            exact
            path="/create"
            render={(props) => <CreateComponent {...props} />}
          />
          <Route exact path="/terms" render={(props) => <Terms {...props} />} />
          <Route
            exact
            path="/contact"
            render={(props) => <Contact {...props} />}
          />
        </Switch>
      </Fragment>
    );
  }
}

export default withApollo(App);
