import React, { Component, Fragment } from 'react';
import { withApollo } from 'react-apollo';

import Loading from '../components/common/Loading';
import NavBar from '../components/common/NavBar';
import Result from '../components/common/Result';

import { CREATE_COMPONENT } from '../graphql/mutations/Results';

import { authenticateSession } from '../utils';

class CreateComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      internalPartId: "",
      manufacturerPartNumber: "",
      manufacturerName: "",
      partDescription: "",
      quantityOnHand: "",
      minimumOrderQuantity: "",
      orderIncrementPackQuantity: "",
      dateCodeRange: "",
      resalePrice: "",
      sellerCost: "",
      countryOfOrigin: "",
      warrantyVerifiedStock: "",
      warehouseCode: "",
      packageType: "",
      imageLink: "",
      datasheetLink: "",
      inventoryCart: "",
      success: "",
      errors: {
        internalPartId: "",
        manufacturerPartNumber: "",
        manufacturerName: "",
        partDescription: "",
        graphQL: ""
      }
    };
  };

  componentDidUpdate (prevProps, prevState) {
    const inventoryCart = JSON.parse(localStorage.getItem("inventoryCart"));
    authenticateSession();

    if (inventoryCart) {
      this.setState({
        inventoryCart: inventoryCart
      });
    };
  };

  onChange = (event) => {
    this.setState({
      success: "",
      errors: {
        internalPartId: "",
        manufacturerPartNumber: "",
        manufacturerName: "",
        partDescription: "",
        graphQL: ""
      }
    });

    this.setState({
      [event.target.id]: event.target.value
    })
  };

  onSubmit = async (event) => {
    const { client } = this.props;
    const {
      internalPartId, manufacturerPartNumber, manufacturerName, partDescription,
      quantityOnHand, minimumOrderQuantity, orderIncrementPackQuantity, dateCodeRange,
      resalePrice, sellerCost, countryOfOrigin, warrantyVerifiedStock, warehouseCode,
      packageType, imageLink, datasheetLink
    } = this.state;
    const userSellerId = localStorage.getItem("userSellerId");
    let errors = 0;

    if (internalPartId === "") {
      errors++;
      this.setState({
        errors: {
          internalPartId: "Please the internal part ID."
        }
      });
    } else if (manufacturerPartNumber === "") {
      errors++;
      this.setState({
        errors: {
          manufacturerPartNumber: "Please the manufacturer part number."
        }
      });
    } else if (manufacturerName === "") {
      errors++;
      this.setState({
        errors: {
          manufacturerName: "Please type the manufacturer name."
        }
      });
    } else if (partDescription === "") {
      errors++;
      this.setState({
        errors: {
          partDescription: "Please type the part description."
        }
      });
    };

    if (errors === 0) {
      this.setState({
        isLoading: true
      });

      try {
        await client.mutate({
          mutation: CREATE_COMPONENT,
          variables: {
            sellerId: userSellerId,
            internalPartId: internalPartId,
            manufacturerPartNumber: manufacturerPartNumber,
            manufacturerName: manufacturerName,
            partDescription: partDescription,
            quantityOnHand: quantityOnHand,
            minimumOrderQuantity: minimumOrderQuantity,
            orderIncrementPackQuantity: orderIncrementPackQuantity,
            dateCodeRange: dateCodeRange,
            resalePrice: resalePrice,
            sellerCost: sellerCost,
            countryOfOrigin: countryOfOrigin,
            warrantyVerifiedStock: warrantyVerifiedStock,
            warehouseCode: warehouseCode,
            packageType: packageType,
            imageLink: imageLink,
            datasheetLink: datasheetLink
          }
        });

        this.setState({
          isLoading: false,
          success: "Component has been created."
        });

        setTimeout(() => {
          this.props.history.push("/results/0");
        }, 1000);

      } catch (error) {
        this.setState({
          isLoading: false,
          errors: {
            graphQL: error.message
          }
        })
      };
    }
  };

  closeResult = () => {
    this.setState({
      success: "",
      errors: {
        internalPartId: "",
        manufacturerPartNumber: "",
        manufacturerName: "",
        partDescription: "",
        graphQL: ""
      }
    });
  };

  render () {
    const { history } = this.props;
    const {
      isLoading, internalPartId, manufacturerPartNumber, manufacturerName, partDescription,
      quantityOnHand, minimumOrderQuantity, orderIncrementPackQuantity, dateCodeRange,
      resalePrice, sellerCost, countryOfOrigin, warrantyVerifiedStock, warehouseCode,
      packageType, imageLink, datasheetLink, inventoryCart, success, errors
    } = this.state;

    return (
      <Fragment>
        {isLoading &&
          <Loading
            loadingLabel={"Please wait..."}
          />
        }

        {(errors.internalPartId !== "" ||
          errors.manufacturerPartNumber !== "" ||
          errors.manufacturerName !== "" ||
          errors.partDescription !== "" ||
          errors.graphQL !== "" ||
          success !== "") &&
          <Result
            resultVariant={
              errors.internalPartId !== "" ||
              errors.manufacturerPartNumber !== "" ||
              errors.manufacturerName !== "" ||
              errors.partDescription !== "" ||
              errors.graphQL !== "" ? "error" : "success"}
            resultText={
              errors.internalPartId ||
              errors.manufacturerPartNumber ||
              errors.manufacturerName ||
              errors.partDescription ||
              errors.graphQL ||
              success}
            resultOnClick={this.closeResult}
          />
        }

        <section className="hero is-dark is-fullheight">
          <div className="hero-head">
            <NavBar
              history={history}
              isLoggedIn={false}
              inventoryData={null}
              inventoryCart={inventoryCart}
              componentDetails={null}
            />
          </div>

          <div className="hero-body">
            <div className="container has-text-centered">
              <div className="columns is-centered">
                <div className="column is-half">
                  <h1 className="title">
                    Create Component
                  </h1>
                  <div className="field">
                    <div className="control">
                      <input
                        id="internalPartId"
                        className="input is-dark is-inverted"
                        type="text"
                        value={internalPartId}
                        onChange={(event) => this.onChange(event)}
                        placeholder="Internal Part Id"
                      />
                    </div>
                  </div>

                  <div className="field">
                    <div className="control">
                      <input
                        id="manufacturerPartNumber"
                        className="input is-dark is-inverted"
                        type="text"
                        value={manufacturerPartNumber}
                        onChange={(event) => this.onChange(event)}
                        placeholder="Manufacturer Part Number"
                      />
                    </div>
                  </div>

                  <div className="field">
                    <div className="control">
                      <input
                        id="manufacturerName"
                        className="input is-dark is-inverted"
                        type="text"
                        value={manufacturerName}
                        onChange={(event) => this.onChange(event)}
                        placeholder="Manufacturer Name"
                      />
                    </div>
                  </div>

                  <div className="field">
                    <div className="control">
                      <input
                        id="partDescription"
                        className="input is-dark is-inverted"
                        type="text"
                        value={partDescription}
                        onChange={(event) => this.onChange(event)}
                        placeholder="Part Description"
                      />
                    </div>
                  </div>

                  <div className="field">
                    <div className="control">
                      <input
                        id="quantityOnHand"
                        className="input is-dark is-inverted"
                        type="text"
                        value={quantityOnHand}
                        onChange={(event) => this.onChange(event)}
                        placeholder="Quantity On Hand"
                      />
                    </div>
                  </div>

                  <div className="field">
                    <div className="control">
                      <input
                        id="minimumOrderQuantity"
                        className="input is-dark is-inverted"
                        type="text"
                        value={minimumOrderQuantity}
                        onChange={(event) => this.onChange(event)}
                        placeholder="Minimum Order Quantity"
                      />
                    </div>
                  </div>

                  <div className="field">
                    <div className="control">
                      <input
                        id="orderIncrementPackQuantity"
                        className="input is-dark is-inverted"
                        type="text"
                        value={orderIncrementPackQuantity}
                        onChange={(event) => this.onChange(event)}
                        placeholder="Order Increment Pack Quantity"
                      />
                    </div>
                  </div>

                  <div className="field">
                    <div className="control">
                      <input
                        id="dateCodeRange"
                        className="input is-dark is-inverted"
                        type="text"
                        value={dateCodeRange}
                        onChange={(event) => this.onChange(event)}
                        placeholder="Date Code Range"
                      />
                    </div>
                  </div>

                  <div className="field">
                    <div className="control">
                      <input
                        id="resalePrice"
                        className="input is-dark is-inverted"
                        type="text"
                        value={resalePrice}
                        onChange={(event) => this.onChange(event)}
                        placeholder="Resale Price"
                      />
                    </div>
                  </div>

                  <div className="field">
                    <div className="control">
                      <input
                        id="sellerCost"
                        className="input is-dark is-inverted"
                        type="text"
                        value={sellerCost}
                        onChange={(event) => this.onChange(event)}
                        placeholder="Seller Cost"
                      />
                    </div>
                  </div>

                  <div className="field">
                    <div className="control">
                      <input
                        id="countryOfOrigin"
                        className="input is-dark is-inverted"
                        type="text"
                        value={countryOfOrigin}
                        onChange={(event) => this.onChange(event)}
                        placeholder="Country Of Origin"
                      />
                    </div>
                  </div>

                  <div className="field">
                    <div className="control">
                      <input
                        id="warrantyVerifiedStock"
                        className="input is-dark is-inverted"
                        type="text"
                        value={warrantyVerifiedStock}
                        onChange={(event) => this.onChange(event)}
                        placeholder="Warranty Verified Stock"
                      />
                    </div>
                  </div>

                  <div className="field">
                    <div className="control">
                      <input
                        id="warehouseCode"
                        className="input is-dark is-inverted"
                        type="text"
                        value={warehouseCode}
                        onChange={(event) => this.onChange(event)}
                        placeholder="Warehouse Code"
                      />
                    </div>
                  </div>

                  <div className="field">
                    <div className="control">
                      <input
                        id="packageType"
                        className="input is-dark is-inverted"
                        type="text"
                        value={packageType}
                        onChange={(event) => this.onChange(event)}
                        placeholder="Package Type"
                      />
                    </div>
                  </div>

                  <div className="field">
                    <div className="control">
                      <input
                        id="imageLink"
                        className="input is-dark is-inverted"
                        type="text"
                        value={imageLink}
                        onChange={(event) => this.onChange(event)}
                        placeholder="Image Link"
                      />
                    </div>
                  </div>

                  <div className="field">
                    <div className="control">
                      <input
                        id="datasheetLink"
                        className="input is-dark is-inverted"
                        type="text"
                        value={datasheetLink}
                        onChange={(event) => this.onChange(event)}
                        placeholder="Datasheet Link"
                      />
                    </div>
                  </div>

                  <div
                    className="field"
                    style={{ marginTop: "30px" }}
                  >
                    <div className="control has-text-centered">
                      <button
                        type="button"
                        className="button is-info"
                        onClick={(event) => this.onSubmit(event)}
                      >
                        Create Component
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

export default withApollo(CreateComponent);
