import gql from 'graphql-tag';

export const USER_LOGIN = gql`
  mutation (
  	$userEmail: String!
  	$userPassword: String!
  ) {
	  userLogin(
	    userEmail: $userEmail
	    userPassword: $userPassword
	  ) {
	    token
	    user {
	    	userSellerId
	    	userOrders {
	    		id
	    		componentId
		      sellerId
				  manufacturerPartNumber
				  manufacturerName
				  partDescription
				  quantityAvailable
				  minimumOrderQuantity
				  orderIncrementPackQuantity
				  dateCodeRange
				  resalePrice
				  sellerCost
				  authorisedChannel
				  leadTime
				  packageType
				  imageLink
				  datasheetLink
	    	}
	    }
	  }
	}
`;