import React, { Component, Fragment } from "react";
import { withApollo } from "react-apollo";

import Loading from "../components/common/Loading";
import NavBar from "../components/common/NavBar";
import Footer from "../components/common/Footer";
import Result from "../components/common/Result";

import {
  GET_NUMBER_OF_COMPONENTS,
  SEARCH_COMPONENTS,
} from "../graphql/queries/Components";
import { UPDATE_ORDER } from "../graphql/mutations/Checkout";

import { URLParams } from "../utils";

import defaultAltPng from "../assets/png/default-alt.png";
import xStockSvg from "../assets/svg/xstock.svg";

class Default extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      section: "home",
      searchTerm: "",
      numberOfInventoryData: 0,
      inventoryDataPage: 0,
      inventoryCart: [],
      orderTotal: 0,
      success: "",
      errors: {
        noResults: "",
        graphQL: "",
      },
    };
  }

  getNumberOfComponents = async () => {
    const { client } = this.props;

    try {
      const response = await client.query({
        query: GET_NUMBER_OF_COMPONENTS,
      });

      let { data } = response;

      this.setState({
        isLoading: false,
        numberOfInventoryData: data.getNumberOfComponents.value,
      });
    } catch (error) {
      console.log(error);
    }
  };

  confirmOrder() {
    this.setState({
      success: "Order has been confirmed. Please wait...",
    });

    setTimeout(() => {
      this.setState({
        success: "",
      });

      this.getNumberOfComponents();

      this.props.history.push("/");
    }, 2500);
  }

  async componentDidMount() {
    const { client, history } = this.props;

    if (history?.location?.state?.goTo === "excess") {
      this.setState({
        section: history?.location?.state?.goTo,
      });
    }

    const confirmOrder = URLParams("confirmOrder");
    const orderId = URLParams("orderId");
    const inventoryCart = JSON.parse(localStorage.getItem("inventoryCart"));

    this.setState({
      isLoading: true,
    });

    if (confirmOrder && orderId) {
      try {
        await client.mutate({
          mutation: UPDATE_ORDER,
          variables: {
            orderId: orderId,
            orderUpdate: "confirm",
            orderStatus: "Confirmed",
          },
        });

        this.confirmOrder();
      } catch (error) {
        console.log(error);
      }
    } else {
      this.getNumberOfComponents();
    }

    if (inventoryCart) {
      this.setState({
        inventoryCart: inventoryCart,
      });
    }
  }

  onChange = (event) => {
    this.setState({
      searchTerm: event.target.value,
    });
  };

  search = async (value) => {
    const { client, history } = this.props;
    const { inventoryDataPage } = this.state;

    try {
      const response = await client.query({
        query: SEARCH_COMPONENTS,
        variables: {
          search: value,
        },
      });

      let { data } = response;

      this.setState({
        isLoading: false,
      });

      if (data.searchComponents.length > 1) {
        history.push(`/results/${inventoryDataPage}/${value}`);
      } else if (data.searchComponents.length === 1) {
        history.push(`/details/${data.searchComponents[0].id}/${value}`);
      } else if (data.searchComponents.length === 0) {
        this.setState({
          errors: {
            noResults: "No results found.",
          },
        });
      }
    } catch (error) {
      this.setState({
        isLoading: false,
        errors: {
          graphQL: error.message,
        },
      });
    }
  };

  searchInventory = (event) => {
    event.preventDefault();
    const { searchTerm } = this.state;

    if (searchTerm !== "") {
      this.setState({
        isLoading: true,
      });

      this.search(searchTerm);
    }
  };

  updateCart = (action, data) => {
    const inventoryCartList = this.state.inventoryCart;

    switch (action) {
      case "add":
        inventoryCartList.push(data);
        this.setState({
          inventoryCart: inventoryCartList,
        });
        localStorage.setItem(
          "inventoryCart",
          JSON.stringify(inventoryCartList)
        );
        break;
      case "remove":
        const index = inventoryCartList.findIndex((obj) => obj.id === data.id);
        inventoryCartList.splice(index, 1);
        this.setState({
          inventoryCart: inventoryCartList,
        });
        localStorage.setItem(
          "inventoryCart",
          JSON.stringify(inventoryCartList)
        );
        break;
      default:
        break;
    }

    this.orderTotal();
  };

  updateQuantity = (data, value) => {
    const inventoryCartList = this.state.inventoryCart;
    const component = data;
    const index = inventoryCartList.findIndex((obj) => obj.id === component.id);

    inventoryCartList[index].quantity = value;

    this.setState({
      inventoryCart: inventoryCartList,
    });

    localStorage.setItem("inventoryCart", JSON.stringify(inventoryCartList));

    this.orderTotal();
  };

  orderTotal = () => {
    const inventoryCart = JSON.parse(localStorage.getItem("inventoryCart"));
    let total = 0.0;

    inventoryCart.map((component) => {
      total =
        total +
        parseFloat(
          component.resalePrice.replace(",", ".") * component.quantity
        );
      return null;
    });

    this.setState({
      orderTotal: total.toFixed(2),
    });
  };

  closeResult = () => {
    this.setState({
      success: "",
      errors: {
        noResults: "",
        graphQL: "",
      },
    });
  };

  render() {
    const { history } = this.props;
    const {
      isLoading,
      section,
      numberOfInventoryData,
      inventoryCart,
      success,
      errors,
    } = this.state;

    return (
      <Fragment>
        {(errors.noResults !== "" ||
          errors.graphQL !== "" ||
          success !== "") && (
          <Result
            resultVariant={
              errors.noResults !== "" || errors.graphQL !== ""
                ? "error"
                : "success"
            }
            resultText={errors.noResults || errors.graphQL || success}
            resultOnClick={this.closeResult}
          />
        )}

        {isLoading && <Loading loadingLabel={"Please wait..."} />}

        <section className="hero is-dark is-fullheight default">
          <div className="hero-head">
            <NavBar
              history={history}
              client={null}
              isLoggedIn={false}
              inventoryData={null}
              inventoryCart={inventoryCart}
              componentDetails={null}
              updateCart={this.updateCart}
              updateQuantity={this.updateQuantity}
            />
          </div>

          <div className="hero-body">
            <div className="container">
              <div className="columns">
                {/* Home */}
                <div className="column is-two-thirds">
                  <img src={xStockSvg} alt="xStock" width="250" height="62" />
                  <h2 className="subtitle tagline">
                    <span>SA's first search engine</span> for local inventories
                  </h2>

                  <button
                    className={
                      section === "contract"
                        ? "button is-info is-active"
                        : "button is-info"
                    }
                    onClick={() =>
                      this.setState({
                        section: section === "contract" ? "home" : "contract",
                      })
                    }
                  >
                    Contract Manufacturer
                  </button>

                  <button
                    className={
                      section === "excess"
                        ? "button is-info is-active"
                        : "button is-info"
                    }
                    onClick={() =>
                      this.setState({
                        section: section === "excess" ? "home" : "excess",
                      })
                    }
                  >
                    Excess Inventory
                  </button>

                  <button
                    className={
                      section === "kitting"
                        ? "button is-info is-active"
                        : "button is-info"
                    }
                    onClick={() =>
                      this.setState({
                        section: section === "kitting" ? "home" : "kitting",
                      })
                    }
                  >
                    Kitting/Assembly
                  </button>

                  <div className="services-content">
                    {section === "home" && (
                      <>
                        <div className="columns">
                          <div className="column">
                            <h2 className="subtitle">What we do:</h2>
                            <p>
                              XSTOCK SA focuses on three key areas within the
                              electronic component industry:
                            </p>
                            <ol>
                              <li>
                                Excess Inventory management - we buy and sell
                                excess inventory globally
                              </li>
                              <li>
                                Offer customers with kitting services - Complete
                                kits delivered to your preferred CM
                              </li>
                              <li>
                                Sourcing and Supply - Importing electronic
                                components
                              </li>
                              <li>
                                Complete Turnkey solutions - Your completed
                                product delivered to you. Fully assembly and
                                testing available.
                              </li>
                            </ol>
                          </div>
                          <div className="column">
                            <h2 className="subtitle">Who we are:</h2>
                            <p>
                              With over 20 years experience in the electronic
                              component industry, XSTOCK South Africa (XSTOCK
                              SA) founded by Ricolene Vadivelu in 2019 during
                              the global electronic shortage and started
                              official operations in 2020. Located in
                              Johannesburg, South Africa.
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                    {section === "contract" && (
                      <ul>
                        <li>
                          <h2 className="subtitle">
                            Complete turnkey solutions
                          </h2>
                        </li>
                        <li>
                          <h2 className="subtitle">Rapid prototyping</h2>
                        </li>
                        <li>
                          <h2 className="subtitle">
                            Small to medium production runs
                          </h2>
                        </li>
                      </ul>
                    )}

                    {/* Search */}
                    {section === "excess" && (
                      <>
                        <h2 className="subtitle">
                          Search Our Electronic Inventory Listings (
                          {numberOfInventoryData} current listings)
                        </h2>

                        <form onSubmit={(event) => this.searchInventory(event)}>
                          <div className="field">
                            <p className="control has-icons-left">
                              <input
                                className="input is-dark"
                                type="text"
                                placeholder="Search by seller ID, manufacturer part number, manufacturer name or description..."
                                onChange={(event) => this.onChange(event)}
                              />
                              <span className="icon is-small is-left">
                                <i className="fas fa-search" />
                              </span>
                            </p>
                          </div>
                        </form>

                        {numberOfInventoryData > 0 && (
                          <p>
                            <a href="/results/0">Browse all listings</a>
                          </p>
                        )}
                      </>
                    )}

                    {/* Kitting */}
                    {section === "kitting" && (
                      <ul>
                        <li>
                          <h2 className="subtitle">
                            Complete kits delivered to your preferred CM
                          </h2>
                        </li>
                      </ul>
                    )}
                  </div>
                </div>

                <div className="column"></div>
              </div>
            </div>
          </div>
          <Footer history={history} />
          <img className="hero-image" alt={"XStock"} src={defaultAltPng} />
        </section>
      </Fragment>
    );
  }
}

export default withApollo(Default);
