import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import HttpsRedirect from 'react-https-redirect';
import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { getMainDefinition } from 'apollo-utilities';
import { ApolloLink, split } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { InMemoryCache } from 'apollo-cache-inmemory';

import App from './App';
import * as serviceWorker from './serviceWorker';

import './sass/style.scss';

require('dotenv').config();

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_SERVER_URL}`,
});

const wsLink = new WebSocketLink({
  uri: `${process.env.REACT_APP_WEBSOCKET_URL}`,
  options: {
    reconnect: true,
  },
});

const terminatingLink = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  httpLink
);

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => {
    const token = localStorage.getItem('token');

    if (token) {
      headers = { ...headers, Authorization: token };
    }

    return { headers };
  });

  return forward(operation);
});

const link = ApolloLink.from([authLink, terminatingLink]);

const cache = new InMemoryCache();

const client = new ApolloClient({
  link,
  cache,
});

ReactDOM.render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <HttpsRedirect>
        <App />
      </HttpsRedirect>
    </ApolloProvider>
  </BrowserRouter>,
  document.getElementById('app')
);

serviceWorker.unregister();
