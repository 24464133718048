import React, { Component, Fragment } from "react";
import { withApollo } from "react-apollo";

import Loading from "../components/common/Loading";
import NavBar from "../components/common/NavBar";
import Footer from "../components/common/Footer";
import Result from "../components/common/Result";

import { USER_REGISTER } from "../graphql/mutations/Register";

class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      inventoryCart: [],
      userName: "",
      userEmail: "",
      userPassword: "",
      confirmPassword: "",
      userSellerId: "",
      success: "",
      errors: {
        userName: "",
        userEmail: "",
        userPassword: "",
        userSellerId: "",
        graphQL: "",
      },
    };
  }

  componentDidMount() {
    const inventoryCart = JSON.parse(localStorage.getItem("inventoryCart"));

    if (inventoryCart) {
      this.setState({
        inventoryCart: inventoryCart,
      });
    }

    document.addEventListener(
      "keyup",
      function (event) {
        if (event.key === "Enter") {
          this.onSubmit(event);
        }
      }.bind(this)
    );
  }

  onChange = (event) => {
    this.setState({
      success: "",
      errors: {
        userName: "",
        userEmail: "",
        userPassword: "",
        userSellerId: "",
        graphQL: "",
      },
    });

    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  onSubmit = async (event) => {
    const { client } = this.props;
    const { userName, userEmail, userPassword, confirmPassword, userSellerId } =
      this.state;
    let errors = 0;

    if (userName === "") {
      errors++;
      this.setState({
        errors: {
          userName: "Please type your userName.",
        },
      });
    } else if (userEmail === "") {
      errors++;
      this.setState({
        errors: {
          userEmail: "Please type your userEmail.",
        },
      });
    } else if (userPassword === "") {
      errors++;
      this.setState({
        errors: {
          userPassword: "Please type your userPassword.",
        },
      });
    } else if (userPassword !== confirmPassword) {
      errors++;
      this.setState({
        errors: {
          userPassword: "Passwords don't match, please try again.",
        },
      });
    } else if (userSellerId === "") {
      errors++;
      this.setState({
        errors: {
          userPassword: "Please type your seller ID.",
        },
      });
    }

    if (errors === 0) {
      this.setState({
        isLoading: true,
      });

      try {
        await client.mutate({
          mutation: USER_REGISTER,
          variables: {
            userName: userName,
            userEmail: userEmail,
            userPassword: userPassword,
            userSellerId: userSellerId,
          },
        });

        this.setState({
          isLoading: false,
          success:
            "Your account has been created, you will be re-directed to login.",
        });

        setTimeout(() => {
          this.props.history.push("/login");
        }, 1000);
      } catch (error) {
        this.setState({
          isLoading: false,
          errors: {
            graphQL: error.message,
          },
        });
      }
    }
  };

  updateCart = (action, data) => {
    const inventoryCartList = this.state.inventoryCart;

    switch (action) {
      case "add":
        inventoryCartList.push(data);
        this.setState({
          inventoryCart: inventoryCartList,
        });
        localStorage.setItem(
          "inventoryCart",
          JSON.stringify(inventoryCartList)
        );
        break;
      case "remove":
        const index = inventoryCartList.findIndex((obj) => obj.id === data.id);
        inventoryCartList.splice(index, 1);
        this.setState({
          inventoryCart: inventoryCartList,
        });
        localStorage.setItem(
          "inventoryCart",
          JSON.stringify(inventoryCartList)
        );
        break;
      default:
        break;
    }

    this.orderTotal();
  };

  updateQuantity = (data, value) => {
    const inventoryCartList = this.state.inventoryCart;
    const component = data;
    const index = inventoryCartList.findIndex((obj) => obj.id === component.id);

    inventoryCartList[index].quantity = value;

    this.setState({
      inventoryCart: inventoryCartList,
    });

    localStorage.setItem("inventoryCart", JSON.stringify(inventoryCartList));

    this.orderTotal();
  };

  orderTotal = () => {
    const inventoryCart = JSON.parse(localStorage.getItem("inventoryCart"));
    let total = 0.0;

    inventoryCart.map((component) => {
      total =
        total +
        parseFloat(
          component.resalePrice.replace(",", ".") * component.quantity
        );
      return null;
    });

    this.setState({
      orderTotal: total.toFixed(2),
    });
  };

  closeResult = () => {
    this.setState({
      success: "",
      errors: {
        userName: "",
        userEmail: "",
        userPassword: "",
        userSellerId: "",
        graphQL: "",
      },
    });
  };

  render() {
    const { history } = this.props;
    const {
      isLoading,
      inventoryCart,
      userName,
      userEmail,
      userPassword,
      confirmPassword,
      userSellerId,
      success,
      errors,
    } = this.state;

    return (
      <Fragment>
        {isLoading && <Loading loadingLabel={"Please wait..."} />}

        {(errors.userName !== "" ||
          errors.userEmail !== "" ||
          errors.userPassword !== "" ||
          errors.userSellerId !== "" ||
          errors.graphQL !== "" ||
          success !== "") && (
          <Result
            resultVariant={
              errors.userName !== "" ||
              errors.userEmail !== "" ||
              errors.userPassword !== "" ||
              errors.userSellerId !== "" ||
              errors.graphQL !== ""
                ? "error"
                : "success"
            }
            resultText={
              errors.userName ||
              errors.userEmail ||
              errors.userPassword ||
              errors.userSellerId ||
              errors.graphQL ||
              success
            }
            resultOnClick={this.closeResult}
          />
        )}

        <section className="hero is-dark is-fullheight">
          <div className="hero-head">
            <NavBar
              history={history}
              client={null}
              isLoggedIn={false}
              inventoryData={null}
              inventoryCart={inventoryCart}
              componentDetails={null}
              updateCart={this.updateCart}
              updateQuantity={this.updateQuantity}
            />
          </div>

          <div className="hero-body">
            <div className="container has-text-centered">
              <div className="columns is-centered">
                <div className="column is-two-thirds">
                  <h1 className="title">Register an account</h1>
                  <div className="field">
                    <div className="control">
                      <input
                        id="userName"
                        className="input is-dark is-inverted"
                        type="text"
                        value={userName}
                        onChange={(event) => this.onChange(event)}
                        placeholder="Name"
                      />
                    </div>
                  </div>

                  <div className="field">
                    <div className="control">
                      <input
                        id="userEmail"
                        className="input is-dark is-inverted"
                        type="email"
                        value={userEmail}
                        onChange={(event) => this.onChange(event)}
                        placeholder="Email"
                      />
                    </div>
                  </div>

                  <div className="field">
                    <div className="control">
                      <input
                        id="userPassword"
                        className="input is-dark is-inverted"
                        type="password"
                        value={userPassword}
                        onChange={(event) => this.onChange(event)}
                        placeholder="Password"
                      />
                    </div>
                  </div>

                  <div className="field">
                    <div className="control">
                      <input
                        id="confirmPassword"
                        className="input is-dark is-inverted"
                        type="password"
                        value={confirmPassword}
                        onChange={(event) => this.onChange(event)}
                        placeholder="Confirm Password"
                      />
                    </div>
                  </div>

                  <div className="field">
                    <div className="control">
                      <input
                        id="userSellerId"
                        className="input is-dark is-inverted"
                        type="text"
                        value={userSellerId}
                        onChange={(event) => this.onChange(event)}
                        placeholder="Seller ID"
                      />
                    </div>
                  </div>

                  <div className="field" style={{ marginTop: "30px" }}>
                    <div className="control has-text-centered">
                      <button
                        type="button"
                        className="button is-info"
                        onClick={(event) => this.onSubmit(event)}
                      >
                        Create Account
                      </button>
                    </div>
                  </div>
                </div>
                <div className="column"></div>
              </div>
            </div>
          </div>
          <Footer history={history} />
        </section>
      </Fragment>
    );
  }
}

export default withApollo(Register);
