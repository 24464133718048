import React, { Component, Fragment } from "react";
import { withApollo } from "react-apollo";

import NavBar from "../components/common/NavBar";
import Footer from "../components/common/Footer";

class Terms extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      searchTerm: "",
      numberOfInventoryData: 0,
      inventoryDataPage: 0,
      inventoryCart: [],
      orderTotal: 0,
      success: "",
      errors: {
        noResults: "",
        graphQL: "",
      },
    };
  }

  updateCart = (action, data) => {
    const inventoryCartList = this.state.inventoryCart;

    switch (action) {
      case "add":
        inventoryCartList.push(data);
        this.setState({
          inventoryCart: inventoryCartList,
        });
        localStorage.setItem(
          "inventoryCart",
          JSON.stringify(inventoryCartList)
        );
        break;
      case "remove":
        const index = inventoryCartList.findIndex((obj) => obj.id === data.id);
        inventoryCartList.splice(index, 1);
        this.setState({
          inventoryCart: inventoryCartList,
        });
        localStorage.setItem(
          "inventoryCart",
          JSON.stringify(inventoryCartList)
        );
        break;
      default:
        break;
    }

    this.orderTotal();
  };

  updateQuantity = (data, value) => {
    const inventoryCartList = this.state.inventoryCart;
    const component = data;
    const index = inventoryCartList.findIndex((obj) => obj.id === component.id);

    inventoryCartList[index].quantity = value;

    this.setState({
      inventoryCart: inventoryCartList,
    });

    localStorage.setItem("inventoryCart", JSON.stringify(inventoryCartList));

    this.orderTotal();
  };

  render() {
    const { history } = this.props;
    const { inventoryCart } = this.state;

    return (
      <Fragment>
        <section className="hero is-dark is-fullheight terms">
          <div className="hero-head">
            <NavBar
              history={history}
              client={null}
              isLoggedIn={false}
              inventoryData={null}
              inventoryCart={inventoryCart}
              componentDetails={null}
              updateCart={this.updateCart}
              updateQuantity={this.updateQuantity}
            />
          </div>

          <div className="hero-body">
            <div className="container">
              <div className="columns">
                <div className="column">
                  <h1 className="title">Terms of Use</h1>

                  <h2 className="subtitle">Terms of Use</h2>

                  <p>
                    The following are the terms and conditions or "Terms" that
                    govern use of this XStock (as defined below) website (the
                    "Site"). By using or visiting the Site, you expressly agree
                    to be bound by these Terms and all applicable laws and
                    regulations governing the Site. XStocksa its subsidiaries
                    and/or affiliates ("XStock") reserve the right to change
                    these Terms at any time, effective immediately upon posting
                    on the Site. If you violate these Terms, XStock may
                    terminate your use of the Site, bar you from future use of
                    the Site, and/or take appropriate legal action against you.
                    You agree that XStock owns all intellectual property rights
                    to all content and software on the Site
                  </p>

                  <p>
                    If you have comments, questions or requests please write to{" "}
                    {`${process.env.REACT_APP_CONTACT}`}
                  </p>

                  <h2 className="subtitle">Permitted Use</h2>

                  <p>
                    You agree that you are only authorized to visit and view
                    pages of this Site for your own personal use, and that you
                    shall not duplicate, publish, modify or otherwise distribute
                    the material on this Site for any purpose other than as set
                    forth herein. You may only use the Site as permitted by law,
                    including applicable export and re-export laws and
                    regulations. The software on this Site and any content
                    posted by XStock on the Site is the property of XStock
                    and/or its suppliers and is protected by S.A and
                    international copyright laws. You agree that you will not
                    remove, obscure or alter any legal notices displayed on the
                    Site. You agree that you will not use the Site (i) in
                    connection with or to promote any products, services, or
                    materials, (ii) in any manner or for any purpose that
                    violates any law or regulation, any right of any person,
                    including but not limited to intellectual property rights,
                    rights of privacy, or rights of personality, or (iii) to
                    post any information that disparages or defames any person
                    or entity.
                  </p>

                  <h2 className="subtitle">Links & Search Results</h2>

                  <p>
                    By using the Site to search for or link to another site, you
                    agree and understand that you may not make any claim against
                    XStock for any damages or losses, whatsoever, resulting from
                    your use of the Site to obtain search results or to link to
                    another site.
                  </p>

                  <h2 className="subtitle">Access & Interference</h2>

                  <p>
                    You agree that you will not use any robot, spider, other
                    automatic device, or manual process to monitor or copy our
                    Web pages or the content contained thereon, to access or
                    capture data, or for any other unauthorized purpose without
                    our prior express written permission of XStock. You agree
                    that you will not use any device, software or routine to
                    interfere or attempt to interfere with the proper working of
                    the Site. You agree that you will not take any action that
                    imposes an unreasonable or disproportionately large load on
                    our infrastructure. You agree that you will not copy,
                    reproduce, alter, modify, create derivative works, or
                    publicly display any content (except for your own personal,
                    non-commercial use) from our Web site without the prior
                    expressed written permission of XStock.
                  </p>

                  <h2 className="subtitle">Accounts</h2>

                  <p>
                    Certain functionality on this Site may require the use of an
                    XStock Account. You or your employer (“Customer”) may create
                    your own XStock Account or one may be assigned to you by
                    XStock. Customer is solely responsible for maintaining the
                    security of the IDs and passwords, for insuring that none
                    are made available for any purpose to anyone other than
                    Customer's employees, and for insuring that employees do not
                    share their specifically assigned IDs or passwords with
                    anyone at all, or share a floating ID and password with
                    anyone other than the Customer’s employees. If Customer
                    wishes to revoke an access ID and password, which it may do
                    at any time and must do immediately in the event of the
                    termination of any employee using one, Customer will notify
                    XStock of such revocation. XStock will invalidate the ID and
                    password and, if requested by Customer, will provide
                    replacements. If you suspect that someone else knows or has
                    changed your password, please reset it so no one else can
                    sign in to your account.
                  </p>

                  <h2 className="subtitle">Unauthorized Use Of The Site</h2>

                  <p>
                    Illegal and/or unauthorized uses of the Site, including, but
                    not limited to unauthorized framing of or linking to the
                    Site, or unauthorized use of any robot, spider or other
                    automated device on the site, will be investigated and
                    appropriate legal action will be taken, including without
                    limitation civil, criminal and injunctive redress. You agree
                    that you will comply with any security processes and
                    procedures (such as passwords) specified by XStock with
                    respect to access to or use of the Site. Further, you agree
                    not to access or attempt to access any areas of or through a
                    Site which are not intended for general public access,
                    unless you have been provided with written authorization to
                    do so by XStock.
                  </p>

                  <h2 className="subtitle">User Content</h2>

                  <p>
                    The Site allows you to create or otherwise submit content.
                    You may not place or send any content or information over
                    this system that violates any law or infringes on the rights
                    of any third party. For example, information known to fall
                    under the requirements of the International Traffic in Arms
                    Regulations (ITAR) or Export Administration Regulations
                    (EAR) should not be placed on or sent over this system. When
                    you enter, upload or otherwise submit content to the Site,
                    you give XStock a worldwide license to use, host, store,
                    reproduce, modify, communicate and distribute such content.
                    This license continues even if you stop using our Site (for
                    example, content posted for a forum). XStock reserves the
                    right to delete or eliminate any content you provide. You
                    represent and warrant you have the necessary rights to grant
                    XStock this license for all content submitted to the Site.
                  </p>

                  <h2 className="subtitle">Violation Of The Terms</h2>

                  <p>
                    You understand and agree that in XStock's sole discretion,
                    and without prior notice, XStock may terminate your access
                    to the Site, or exercise any other remedy available if
                    XStock believes that your use is (i) inconsistent with these
                    Terms or (ii) has violated the rights of XStock, another
                    user or the law. You agree that monetary damages may not
                    provide a sufficient remedy to XStock for violations of
                    these terms and conditions and you consent to injunctive or
                    other equitable relief for such violations. XStock may
                    release user information about you if required by law or
                    subpoena, or if the information is necessary or appropriate
                    to release to address an unlawful or harmful activity.
                  </p>

                  <h2 className="subtitle">Copyright</h2>

                  <p>
                    Unless otherwise specified, the copyrights in all
                    copyrightable subject matter on the Site are either owned by
                    XStock or used lawfully by XStock. To the extent that XStock
                    has the right to do so without compensation to third
                    parties, and except for materials or information
                    specifically provided under other terms, XStock grants you
                    permission to copy or otherwise download from the Site,
                    information and materials (including related graphics),
                    provided:
                  </p>

                  <ul>
                    <li>The materials are for internal use only, and</li>
                    <li>
                      Any copies of materials or portions thereof must include
                      the copyright notice specified on the Site
                    </li>
                  </ul>

                  <p>
                    You may not copy or display for redistribution to third
                    parties any portion of the content without the prior written
                    permission of XStock. Documents posted by XStock on the Site
                    may contain other proprietary notices or describe products,
                    services, processes or technologies owned by XStock or third
                    parties. Nothing contained herein shall be construed by
                    implication, estoppel or otherwise as granting to the user a
                    license under any copyright, trademark, patent or other
                    intellectual property right of XStock or any third party.
                  </p>

                  <h2 className="subtitle">Trademark</h2>

                  <p>
                    The XStock name and logo and all related product and service
                    names, design marks and slogans are trademarks, service
                    marks or registered trademarks of XStock and may not be used
                    in any manner without the prior written consent of XStock.
                    Other products and service marks are trademarks of their
                    respective owners.
                  </p>

                  <h2 className="subtitle">Limitation Of Liability</h2>

                  <p>
                    YOU ASSUME ALL RESPONSIBILITY AND RISK FOR THE USE OF THIS
                    SITE AND THE INTERNET GENERALLY. IN NO EVENT SHALL XSTOCK BE
                    LIABLE FOR ANY DIRECT, SPECIAL, INDIRECT, OR CONSEQUENTIAL
                    DAMAGES OR ANY DAMAGES WHATSOEVER; INCLUDING BUT NOT LIMITED
                    TO LOSS OF USE, DATA, OR PROFITS, WITHOUT REGARD TO THE FORM
                    OF ANY ACTION, INCLUDING BUT NOT LIMITED TO CONTRACT,
                    NEGLIGENCE, OR OTHER TORTIOUS ACTIONS, ARISING OUT OF OR IN
                    CONNECTION WITH THE USE OF THIS SITE. XSTOCK MAKES NO
                    WARRANTY AS TO THE ACCURACY, OR COMPLETENESS OR WHETHER THE
                    CONTENT IS UP TO DATE. IT IS YOUR RESPONSIBILITY TO VERIFY
                    ANY INFORMATION BEFORE RELYING ON IT. THE CONTENT OF THIS
                    SITE MAY INCLUDE TECHNICAL INACCURACIES OR TYPOGRAPHICAL
                    ERRORS. FROM TIME TO TIME, CHANGES ARE MADE TO THE CONTENT
                    HEREIN.
                  </p>

                  <h2 className="subtitle">Warranty Disclaimer</h2>

                  <p>
                    XSTOCK DOES NOT PROMISE THAT THE SITE OR ANY SERVICES
                    PROVIDED HEREUNDER WILL BE ERROR- FREE, UNINTERRUPTED, NOR
                    THAT IT WILL PROVIDE SPECIFIC RESULTS FROM USE OF THE SITE
                    OR ANY CONTENT, SEARCH OR LINK ON IT. ACCESS TO THE SITE
                    (INCLUDING ANY INFORMATION OR MATERIALS THEREIN) IS PROVIDED
                    ON AN "AS IS" BASIS, WITHOUT WARRANTIES OF ANY KIND, EITHER
                    EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO,
                    WARRANTIES OF TITLE, NONINFRINGEMENT OR IMPLIED WARRANTIES
                    OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. NO
                    ADVICE OR INFORMATION GIVEN BY XSTOCK, ITS AFFILIATES OR
                    THEIR RESPECTIVE EMPLOYEES SHALL CREATE ANY WARRANTY.
                    NEITHER XSTOCK NOR ITS AFFILIATES WARRANT THAT THE
                    INFORMATION OR MATERIALS ON, OR ACCESS TO, ANY SITE WILL BE
                    WITHOUT INTERRUPTION OR ERROR FREE.
                  </p>

                  <h2 className="subtitle">Disputes</h2>

                  <p>
                    If there is any dispute about or involving the Site, by
                    using the Site, you agree that the dispute will be governed
                    by the laws of the South Africa without regard to its
                    conflict of law provisions. You agree to personal
                    jurisdiction by and venue in the state and federal courts of
                    South Africa.
                  </p>

                  <h2 className="subtitle">Indemnity</h2>

                  <p>
                    You agree to indemnify and hold XStock, its officers, agents
                    and other partners and employees, harmless from any loss,
                    liability, claim or demand, including reasonable attorneys'
                    fees, made by any third party due to or arising out of your
                    use of the Site, including also your use of the Site to
                    provide a link to another site or to upload content or other
                    information to the Site.
                  </p>

                  <h2 className="subtitle">
                    User Policy – Outbound Linking Policy Statement
                  </h2>

                  <p>
                    Any link (including hyperlinks, buttons or referral devices
                    of any kind) used on this or any other XStock Web page is
                    provided for the use and convenience of the visitor. The
                    appearance of a link on this or any other XStock Web page
                    does not constitute an endorsement, recommendation or
                    certification by XStock, nor should the presence of a link
                    in any way be construed as a suggestion that the site has
                    any relationship with XStock.
                  </p>

                  <h2 className="subtitle">
                    User Policy - Inbound Linking Policy Statement
                  </h2>

                  <p>
                    XStock generally does not object to links to XStock Web
                    sites from third party sites. However, there are certain
                    conditions that must be followed:
                  </p>

                  <ul>
                    <li>
                      Unless we enter into a specific written agreement with
                      you, you may not use any of XStock's names, logos,
                      designs, slogans, product trademarks or service marks in
                      or with your links, except that you may link to an XStock
                      site using the plain text name of that site or the plain
                      text name of XStock.
                    </li>
                    <li>
                      Do not present the link to XStock's site in any way that
                      suggests XStock has any relationship or affiliation with
                      your site or endorses, sponsors or recommends the
                      information, products or services on your site. Do not use
                      any of XStock's names, logos, designs, slogans, product
                      trademarks or service marks in any advertising, publicity,
                      promotion, or in any other commercial manner without the
                      prior express written permission for a particular use from
                      XStock.
                    </li>
                    <li>
                      Link only to the home page/first page/registration page of
                      this site and do not incorporate any content from this
                      site into your site (e.g., by in-lining or framing).
                    </li>
                    <li>
                      LDo not use any of XStock's names, logos, designs,
                      slogans, product trademark or service parks, or any other
                      words or codes identifying XStock's Web sites in any "meta
                      tag" or other information used by search engines or other
                      information location tools to identify and select sites,
                      without XStock's express written permission for a
                      particular use.
                    </li>
                  </ul>

                  <h2 className="subtitle">
                    Customer Rewards Terms & Conditions
                  </h2>

                  <p>
                    The Special Pricing you receive through the XStock Customer
                    Rewards program is proprietary and confidential. While we
                    will do our best to alert you ahead of time of any changes
                    to the program in general or your status within the program,
                    XStock reserves the right to terminate or change the pricing
                    terms and Customer Rewards program at any time.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <Footer history={history} />
        </section>
      </Fragment>
    );
  }
}

export default withApollo(Terms);
