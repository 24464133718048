import React, { Fragment } from 'react';

import { svgAssetStyles } from '../../utils';

import addSvg from '../../assets/svg/add.svg';
import timesSvg from '../../assets/svg/times.svg';

export default function ResultsTable (props) {
  const { history, match, tableData, tableCart, tableUpdateCart, deleteComponent, isLoggedIn } = props;

  return (
    <table className="table is-fullwidth is-hoverable is-inverted is-bordered is-striped ">
		  <thead>
		    <tr>
				  <th>Manufacturer Part Number</th>
				  <th>Manufacturer Name</th>
				  <th>Part Description</th>
				  <th>Price</th>
				  <th>Available Quantity</th>
				  {/*<th>Ships In</th>*/}
				  <th></th>
		    </tr>
		  </thead>
		  <tbody>
		  	{tableData.length > 0 && tableData.map((data, index) => {
		  		const dataIndex = tableCart.findIndex(obj => obj.id === data.id);
		  		return (
		  			<Fragment key={index}>
		  				{data.quantityAvailable > 0 &&
		  					<tr
				  				key={index}
				  			>
				  				<td>
				  					{data.manufacturerPartNumber}
				  				</td>
				  				<td>
				  					{data.manufacturerName}
				  				</td>
				  				<td>
				  					{data.partDescription}
				  				</td>
				  				<td>
				  					R {parseFloat(data.resalePrice.replace(",", ".")).toFixed(2)}
				  				</td>
				  				<td>
				  					{data.quantityAvailable}
				  				</td>
				  				{/*<td>
				  					{convertToShippingDays(data.leadTime)}
				  				</td>*/}
			  					<td>
				  					<div className="field has-addons">
										  <p className="control">
										    <button
										    	className="button is-dark is-half"
										    	onClick={() => match.params.string ? history.push(`/details/${data.id}/${match.params.string}`) : history.push(`/details/${data.id}`)}
										    >
										      <span>View Details</span>
										    </button>
										  </p>

										  {dataIndex === -1 &&
										  	<p className="control">
											    <button
											    	className="button is-info is-half"
											    	onClick={() => tableUpdateCart("add", data)}
											    >
										      	<img
						                  src={addSvg}
						                  alt="Add to Cart"
						                  style={svgAssetStyles("2px", "5px", "0px", null, "12px", "1", null)}
						                /> Cart
											    </button>
											  </p>
										  }

										  {dataIndex > -1 &&
										  	<p className="control">
											    <button
											    	className="button is-danger is-half"
											    	onClick={() => tableUpdateCart("remove", data)}
											    >
											      <img
						                  src={timesSvg}
						                  alt="Remove from Cart"
						                  style={svgAssetStyles("2px", "5px", "0px", null, "10px", "1", null)}
						                /> Cart
											    </button>
											  </p>
										  }

										  {isLoggedIn &&
										  	<p className="control">
											    <button
											    	className="button is-dark is-half"
											    	onClick={(event, id) => deleteComponent(event, data.id)}
											    >
											      <span>Delete</span>
											    </button>
											  </p>
										  }
										</div>
				  				</td>
				  			</tr>
		  				}
		  			</Fragment>
		  		)
		  	})}

		  	{tableData.length === 0 &&
		  		<tr>
			  		<td colSpan={5}>No data</td>
			  	</tr>
		  	}
		  </tbody>
		</table>
  );
}