import React from 'react';
import classNames from 'classnames';

export default function Result(props) {
  const { resultVariant, resultText, resultOnClick } = props;

  const toastHeaderClass = classNames("toast-header", {
    "is-error": resultVariant === "error",
    "is-success": resultVariant === "success"
  });

  return (
    <div
      className="toast"
      style={{
        color: resultVariant === "error" ? "#cc0f35" : "#257942",
        borderColor: resultVariant === "error" ? "#feecf0" : "#effaf3"
      }}
    >
      <div className={toastHeaderClass}>
        <strong className="mr-auto">{resultVariant === "error" ? "Error" : "Success"}</strong>

        <button
          type="button"
          aria-label="Close"
          onClick={(event) => resultOnClick(event)}
        >
          <i className="fas fa-times" />
        </button>
      </div>

      <div className="toast-body">
        {resultText && resultText.replace("GraphQL error:", "")}
      </div>
    </div>
  );
};