import gql from "graphql-tag";

export const CONTACT_REQUEST = gql`
  mutation (
    $fullName: String!
    $email: String!
    $notes: String!
    $offersUpdates: Boolean!
  ) {
    contactRequest(
      fullName: $fullName
      email: $email
      notes: $notes
      offersUpdates: $offersUpdates
    ) {
      success
    }
  }
`;
