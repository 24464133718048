import gql from 'graphql-tag';

export const SEARCH_COMPONENTS = gql`
  query (
  	$page: Int
  	$search: String!
  ) {
    searchComponents (
    	page: $page
    	search: $search
    ) {
      id
      sellerId
		  manufacturerPartNumber
		  manufacturerName
		  partDescription
		  quantityAvailable
		  minimumOrderQuantity
		  orderIncrementPackQuantity
		  dateCodeRange
		  resalePrice
		  sellerCost
		  authorisedChannel
		  leadTime
		  packageType
		  imageLink
		  datasheetLink
    }
  }
`;

export const GET_NUMBER_OF_SEARCH_COMPONENTS = gql`
  query (
  	$search: String!
  ) {
    getNumberOfSearchComponents (
    	search: $search
    ) {
      value
    }
  }
`;

export const GET_ALL_COMPONENTS = gql`
  query (
  	$page: Int
 	) {
    getAllComponents (
    	page: $page
    ) {
      id
      sellerId
		  manufacturerPartNumber
		  manufacturerName
		  partDescription
		  quantityAvailable
		  minimumOrderQuantity
		  orderIncrementPackQuantity
		  dateCodeRange
		  resalePrice
		  sellerCost
		  authorisedChannel
		  leadTime
		  packageType
		  imageLink
		  datasheetLink
    }
  }
`;

export const GET_NUMBER_OF_COMPONENTS = gql`
  query {
    getNumberOfComponents {
      value
    }
  }
`;

export const GET_USER_COMPONENTS = gql`
  query {
    getUserComponents {
      id
      sellerId
		  manufacturerPartNumber
		  manufacturerName
		  partDescription
		  quantityAvailable
		  minimumOrderQuantity
		  orderIncrementPackQuantity
		  dateCodeRange
		  resalePrice
		  sellerCost
		  authorisedChannel
		  leadTime
		  packageType
		  imageLink
		  datasheetLink
    }
  }
`;

export const GET_COMPONENT = gql`
  query (
  	$id: String!
  ) {
    getComponent (
    	id: $id
    ) {
      id
      sellerId
		  manufacturerPartNumber
		  manufacturerName
		  partDescription
		  quantityAvailable
		  minimumOrderQuantity
		  orderIncrementPackQuantity
		  dateCodeRange
		  resalePrice
		  sellerCost
		  authorisedChannel
		  leadTime
		  packageType
		  imageLink
		  datasheetLink
    }
  }
`;