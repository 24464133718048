import React, { Component } from "react";
import { withApollo } from "react-apollo";

class Footer extends Component {
  render() {
    const { history } = this.props;

    return (
      <footer className="footer">
        <div className="container">
          <div className="content">
            <ul>
              <li>
                <button
                  className="button is-dark"
                  onClick={(event) =>
                    history.push("/", {
                      goTo: "excess",
                    })
                  }
                >
                  Excess Inventory
                </button>
              </li>
              <li>
                <button
                  className="button is-dark"
                  onClick={(event) => history.push("/contact")}
                >
                  Get In Touch
                </button>
              </li>
              <li>
                <button
                  className="button is-dark"
                  onClick={(event) => history.push("/terms")}
                >
                  Terms Of Use
                </button>
              </li>
              <li>
                <button
                  className="button is-dark"
                  onClick={(event) => history.push("/register")}
                >
                  Register
                </button>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    );
  }
}

export default withApollo(Footer);
