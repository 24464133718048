import React, { Component, Fragment } from "react";

class ContactModal extends Component {
  render() {
    const {
      name,
      email,
      notes,
      modalOnChange,
      modalOnSubmit,
      modalOnClickClose,
    } = this.props;

    return (
      <Fragment>
        <div className="modal is-active">
          <div className="modal-background"></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <h2 className="modal-card-title">Contact Us</h2>
              <button
                className="delete"
                aria-label="close"
                onClick={(event) => modalOnClickClose(event)}
              ></button>
            </header>
            <section className="modal-card-body">
              <form onSubmit={(event) => modalOnSubmit(event)}>
                <div className="field">
                  <div className="control">
                    <input
                      id="name"
                      className="input is-dark is-inverted"
                      type="text"
                      value={name}
                      onChange={(event) => modalOnChange(event)}
                      placeholder="Name & Surname*"
                    />
                  </div>
                </div>

                <div className="field">
                  <div className="control">
                    <input
                      id="email"
                      className="input is-dark is-inverted"
                      type="email"
                      value={email}
                      onChange={(event) => modalOnChange(event)}
                      placeholder="Email*"
                    />
                  </div>
                </div>

                <div className="field">
                  <div className="control">
                    <textarea
                      id="notes"
                      className="input is-dark is-inverted"
                      value={notes}
                      onChange={(event) => modalOnChange(event)}
                      placeholder="Notes*"
                    />
                  </div>
                </div>
              </form>
            </section>
            <footer className="modal-card-foot">
              <button
                className="button is-info"
                onClick={(event) => modalOnSubmit(event)}
              >
                Send Now
              </button>
            </footer>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ContactModal;
