import React from 'react';

export default function Loading(props) {
  const { loadingLabel } = props;

  return (
    <div className="loading">
      <i className="fas fa-2x fa-spinner fa-pulse" />
      <p>{loadingLabel}</p>
    </div>
  );
}