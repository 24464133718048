import gql from 'graphql-tag';

export const CREATE_COMPONENT = gql`
  mutation (
  	$sellerId: String!
	  $manufacturerPartNumber: String!
	  $manufacturerName: String!
	  $partDescription: String!
	  $quantityAvailable: String
	  $minimumOrderQuantity: String
	  $orderIncrementPackQuantity: String
	  $dateCodeRange: String
	  $resalePrice: String
	  $sellerCost: String
	  $authorisedChannel: String
	  $leadTime: String
	  $packageType: String
	  $imageLink: String
	  $datasheetLink: String
  ) {
	  createComponent(
	    sellerId: $sellerId
		  manufacturerPartNumber: $manufacturerPartNumber
		  manufacturerName: $manufacturerName
		  partDescription: $partDescription
		  quantityAvailable: $quantityAvailable
		  minimumOrderQuantity: $minimumOrderQuantity
		  orderIncrementPackQuantity: $orderIncrementPackQuantity
		  dateCodeRange: $dateCodeRange
		  resalePrice: $resalePrice
		  sellerCost: $sellerCost
		  authorisedChannel: $authorisedChannel
		  leadTime: $leadTime
		  packageType: $packageType
		  imageLink: $imageLink
		  datasheetLink: $datasheetLink
	  ) {
	    id
	  }
	}
`;

export const IMPORT_COMPONENTS = gql`
  mutation (
  	$userSellerId: String!
  	$input: [ComponentInput]
  ) {
	  importComponents(
	  	userSellerId: $userSellerId
	    input: $input
	  ) {
	    id
      sellerId
		  manufacturerPartNumber
		  manufacturerName
		  partDescription
		  quantityAvailable
		  minimumOrderQuantity
		  orderIncrementPackQuantity
		  dateCodeRange
		  resalePrice
		  sellerCost
		  authorisedChannel
		  leadTime
		  packageType
		  imageLink
		  datasheetLink
	  }
	}
`;

export const DELETE_ALL_COMPONENTS = gql`
	mutation {
	  deleteAllComponents {
	    success
	  }
	}
`

export const DELETE_COMPONENT = gql`
	mutation (
		$id: String!
	) {
	  deleteComponent (
	  	id: $id
	  ) {
	    success
	  }
	}
`